import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getCookie, setCookie } from '@/utils/cookie';

const version = '-v0.0.11';
export const APP_LANG_NAME = 'auto.ae-dealersapp-lang';
export const LANGS_MAP = {
  en: 'en',
  ru: 'ru'
};
export const LANGS = Object.values(LANGS_MAP);
export const DEFAULT_LANG = 'ru';
export const FALLBACK_LANG = 'en';
export const LANG_NAME_MAP = {
  [LANGS_MAP.en]: 'English',
  [LANGS_MAP.ru]: 'Русский'
};

export function getBrowserLang(): string {
  return window.navigator.language.split('-')[0];
}

export function isValidLang(lang: string): boolean {
  return !!lang && LANGS.includes(lang);
}

export function getInitialLanguage(): string {
  const cookieValue = getCookie(APP_LANG_NAME);

  if (cookieValue) {
    const parsed = JSON.parse(cookieValue);
    if (isValidLang(parsed)) return parsed;
  }

  const browserLang = getBrowserLang();
  if (isValidLang(browserLang)) return browserLang;

  return DEFAULT_LANG;
}

export function saveLangInCookie(lang: string): void {
  setCookie(APP_LANG_NAME, JSON.stringify(lang));
}

// https://react.i18next.com/latest/using-with-hooks
// https://www.i18next.com/overview/configuration-options
i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: `/locales${version}/{{lng}}/{{ns}}.json`
    },
    lng: getInitialLanguage(),
    fallbackLng: FALLBACK_LANG,
    supportedLngs: LANGS,
    ns: 'common',
    cleanCode: true,
    debug: false,
    interpolation: {
      // Not needed for react as it escapes by default
      escapeValue: false
    }
  });

export default i18n;
