import { Skeleton } from '@/ui/Skeleton';

import cls from './Header.module.scss';

export function HeaderSkeleton() {
  return (
    <header className={cls.root}>
      <div className={cls.avatar}>
        <Skeleton width={36} height={36} />
      </div>

      <div className={cls.text}>
        <Skeleton width={118} height={20} style={{ marginBottom: '2px' }} />
        <Skeleton width={126} height={16} />
      </div>

      <div className={cls.ad}>
        <Skeleton width={316} height={22} style={{ marginBottom: '2px' }} />
        <Skeleton width={316} height={18} />
      </div>
    </header>
  );
}
