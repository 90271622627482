export function EmojiIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.4786 22 22.0017 17.4707 22.0017 12C22.0017 6.52137 17.4707 2 11.9921 2C6.52311 2 2 6.52137 2 12C2 17.4707 6.53105 22 12 22ZM12 20.0302C7.54387 20.0302 3.97779 16.4561 3.97779 12C3.97779 7.54387 7.53593 3.96986 11.9921 3.96986C16.4482 3.96986 20.0319 7.54387 20.0319 12C20.0319 16.4561 16.4561 20.0302 12 20.0302Z"
        fill="currentColor"
      />
      <path
        d="M11.9913 16.7634C14.0233 16.7634 15.3822 15.4142 15.3822 14.77C15.3822 14.5343 15.1542 14.4242 14.9405 14.5228C14.2269 14.8996 13.3968 15.3335 11.9913 15.3335C10.5876 15.3335 9.75232 14.8951 9.0518 14.5228C8.83011 14.4225 8.60049 14.5343 8.60049 14.77C8.60049 15.4142 9.9593 16.7634 11.9913 16.7634ZM9.13797 11.1266C9.74438 11.1266 10.2813 10.58 10.2813 9.81422C10.2813 9.03877 9.75232 8.49219 9.13797 8.49219C8.5333 8.49219 8.00781 9.03877 8.00781 9.81422C8.00781 10.58 8.5333 11.1266 9.13797 11.1266ZM14.8509 11.1266C15.4555 11.1266 15.9942 10.58 15.9942 9.81422C15.9942 9.03877 15.4635 8.49219 14.8509 8.49219C14.2427 8.49219 13.719 9.03877 13.719 9.81422C13.719 10.58 14.2427 11.1266 14.8509 11.1266Z"
        fill="currentColor"
      />
    </svg>
  );
}
