import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { SortFilter } from '@/modules/showroom/advert/my/types';
import {
  BREAKPOINT,
  CARS_CATEGORY_ID,
  DEBOUNCE_TIME,
  SEARCH_RESULT_ID
} from '@/modules/showroom/advert/search/consts';
import {
  calcSelectedParamsCount,
  defaultSearchReq,
  storeToSearchReq
} from '@/modules/showroom/advert/search/helpers';
import {
  SearchSelectContext,
  useSearchSelect
} from '@/modules/showroom/advert/search/search-select-ctx';
import { Filters } from '@/modules/showroom/advert/search/SearchAdverts/Filters/Filters';
import { SearchResult } from '@/modules/showroom/advert/search/SearchAdverts/SearchResult/SearchResult';
import { Statuses } from '@/modules/showroom/advert/search/SearchAdverts/Statuses';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { SearchAdvertsReq } from '@/modules/showroom/advert/search/types';

import cls from './SearchAdverts.module.scss';

type Props = {
  categoryId: number;
  req: SearchAdvertsReq;
  showResults: boolean;
  sortFilters: SortFilter[];
};

export function SearchAdverts({
  categoryId,
  req: initialReq,
  showResults,
  sortFilters
}: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const windowWidth = useCommonStore((s) => s.windowWidth);
  const isDesktop = windowWidth > BREAKPOINT;

  const { setStatus, refreshKey } = useContext(MyAdvertsContext);
  const searchStoreValue = useSearchStore((s) => s);
  const searchStore = useMemo(
    () => ({ ...searchStoreValue, categoryId }),
    [categoryId, searchStoreValue]
  );
  const debouncedStore = useDebounce(searchStore, DEBOUNCE_TIME);
  const [, allSelectedParamsCount] = useMemo(
    () => calcSelectedParamsCount(searchStore),
    [searchStore]
  );

  const [, setReq] = useState(initialReq);

  // Search
  const searchReq = useMemo(
    () => storeToSearchReq(debouncedStore),
    [debouncedStore]
  );

  const [searchStateReq, setSearchStateReq] = useState<
    Nullable<SearchAdvertsReq>
  >(showResults ? searchReq : null);

  useEffect(() => {
    if (showResults) {
      setSearchStateReq(searchReq);
    }
  }, [searchReq, showResults]);

  const searchStateReqWithSort = useMemo(
    () =>
      searchStateReq
        ? {
            ...searchStateReq,
            sort_filter_id:
              searchReq.sort_filter_id || searchStateReq.sort_filter_id || null
          }
        : null,
    [searchReq.sort_filter_id, searchStateReq]
  );

  const searchResultReq = useMemo(
    () => (isDesktop ? searchStateReqWithSort : searchReq),
    [isDesktop, searchReq, searchStateReqWithSort]
  );

  // Handlers
  const showReset = allSelectedParamsCount > 0;
  const onResetClick = () => {
    setReq(defaultSearchReq);
    searchStore.resetValues();
    navigate(pathname, { replace: true });
  };

  useEffect(() => {
    return () => {
      setStatus(undefined);
      searchStore.resetValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchClick = () => {
    setSearchStateReq(searchReq);
  };

  useEffect(() => {
    if (showResults) {
      onSearchClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResults]);

  // Search contexts
  const searchSelects = useSearchSelect();

  return (
    <SearchSelectContext.Provider value={searchSelects}>
      <div className={cls.root}>
        <div>
          <Statuses categoryId={categoryId} />
          <div className={cls.container}>
            <Filters
              sortFilters={sortFilters}
              showReset={showReset}
              onResetClick={onResetClick}
            />

            <div id={SEARCH_RESULT_ID} key={refreshKey}>
              {searchResultReq && (
                <SearchResult
                  req={searchResultReq}
                  categoryId={CARS_CATEGORY_ID}
                  onResetClick={onResetClick}
                  selectedParamsCount={allSelectedParamsCount}
                  search={debouncedStore.searchText}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SearchSelectContext.Provider>
  );
}
