import cn from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from '@/modules/chat/Detail/Form/Form';
import { FormSkeleton } from '@/modules/chat/Detail/Form/FormSkeleton';
import { HeaderSkeleton } from '@/modules/chat/Detail/Header/HeaderSkeleton';
import { HeaderWrap } from '@/modules/chat/Detail/Header/HeaderWrap';
import { Previews } from '@/modules/chat/Detail/Messages/Message/ContentMedia/Previews/Previews';
import { Messages } from '@/modules/chat/Detail/Messages/Messages';
import { ProfileWrapper } from '@/modules/chat/Detail/Profile/ProfileWrapper';
import { SearchBar } from '@/modules/chat/Detail/SearchBar/SearchBar';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { QuickReplies } from '@/modules/chat/QuickReplies/QuickReplies';
import {
  ChatMessageContent,
  ChatMessageContentType
} from '@/modules/chat/types';

import cls from './Detail.module.scss';

type Props = {
  id: number;
};

function Detail({ id }: Props) {
  const detail = useChatDetailStore((s) => s.detail);
  const isDetailLoading = useChatDetailStore((s) => s.isDetailLoading);

  const isSearchOpen = useChatDetailStore((s) => s.isSearchOpen);
  const isProfileOpen = useChatDetailStore((s) => s.isProfileOpen);
  const fetchDetail = useChatDetailStore((s) => s.fetchDetail);
  const reset = useChatDetailStore((s) => s.reset);

  const setMessagesContent = useChatDetailStore((s) => s.setMessagesContent);
  const messagesContent = useChatDetailStore((s) => s.messagesContent);
  const isPreviewOpen = useChatDetailStore((s) => s.isPreviewOpen);
  const setPreviewOpen = useChatDetailStore((s) => s.setPreviewOpen);
  const setPreviewIndex = useChatDetailStore((s) => s.setPreviewIndex);
  const messages = useChatDetailStore((s) => s.messages);

  useEffect(() => {
    if (isProfileOpen) return;

    const content: ChatMessageContent[] = [];
    messages
      .filter((message) => message.content)
      .forEach((message) =>
        message.content.forEach(
          (media) =>
            (media.type === ChatMessageContentType.image ||
              media.type === ChatMessageContentType.video) &&
            content.push(media)
        )
      );

    setMessagesContent(content);
    setPreviewIndex(0);
  }, [messages, setMessagesContent, setPreviewIndex, isProfileOpen]);

  useEffect(() => {
    reset();
    fetchDetail(id);

    return () => {
      reset();
    };
  }, [id, fetchDetail, reset]);

  return (
    <div className={cls.wrapper}>
      <div className={cn(cls.root, { [cls.root_profile_open]: isProfileOpen })}>
        {!isDetailLoading && detail && (
          <>
            <HeaderWrap detail={detail} />

            {isSearchOpen && <SearchBar chatId={detail.id} />}
            <Messages detailID={detail.id} key={detail.id} />
            <Form detailID={detail.id} />
          </>
        )}

        {isDetailLoading && (
          <>
            <HeaderSkeleton />
            <div style={{ flex: 1 }} />
            <FormSkeleton />
          </>
        )}
      </div>

      <ProfileWrapper />

      {messagesContent.length > 0 && (
        <Previews isOpen={isPreviewOpen} close={() => setPreviewOpen(false)} />
      )}

      <QuickReplies />
    </div>
  );
}

export function ChatDetailWrap() {
  const { id } = useParams();
  const chatId = id ? Number(id) : null;
  return chatId ? <Detail id={chatId} /> : <></>;
}
