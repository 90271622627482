import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDownloadFile } from '@/hooks/useDownloadFile';
import { useLang } from '@/hooks/useLang';
import { getDocName } from '@/modules/chat/Detail/Messages/Message/helpers';
import { ChatMessageContent } from '@/modules/chat/types';
import { formatDate } from '@/utils/date';
import { bytesToSize } from '@/utils/format';

import cls from './ContentDoc.module.scss';
import { DocIcon } from './icons';

type Props = {
  doc: ChatMessageContent;
  own?: boolean;
  compact?: boolean;
};

export const ContentDoc: React.FC<Props> = ({ doc, own, compact }) => {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [downloadFile] = useDownloadFile();

  const color = own ? 'var(--clr-white)' : 'var(--clr-blue)';
  const name = getDocName(doc);
  const { size } = doc;

  const date = useMemo(
    () => new Date(doc.upload_date * 1000),
    [doc.upload_date]
  );
  const dateStrFull = formatDate(date, 'PPp', lang);

  const ext = doc.extension && compact ? doc.extension.toUpperCase() : '';
  const [formattedSize, unitKey] = bytesToSize(size || 0);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_own]: own,
        [cls.root_compact]: compact
      })}
      style={{ '--doc-color': color } as React.CSSProperties}
    >
      {doc.url && (
        <button
          type="button"
          className={cls.link}
          onClick={() => downloadFile(doc)}
        />
      )}
      <div className={cls.icon}>
        <DocIcon />
      </div>

      <div className={cls.text}>
        <p className={cls.name}>{name}</p>
        <ul className={cls.stats}>
          {size && (
            <li>
              <p>
                {formattedSize} {t(`common.units.${unitKey}`)} {ext}
              </p>
            </li>
          )}
          {compact && (
            <li>
              <p>{dateStrFull}</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
