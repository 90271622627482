import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { markCreditRequestsAsViewed } from '@/modules/credit/api';
import { CreditForm } from '@/modules/credit/CreditForm/CreditForm';
import { CreditRequestsOffState } from '@/modules/credit/CreditRequests/CreditRequestsOffState/CreditRequestsOffState';
import { CreditRequestsPage } from '@/modules/credit/CreditRequests/CreditRequestsPage';
import { MarkIcon, SettingsIcon } from '@/modules/credit/CreditRequests/icons';
import { useCreditForm } from '@/modules/credit/hooks';
import { SortSelect } from '@/modules/showroom/advert/search/SearchAdverts/SortSelect/SortSelect';
import { Button } from '@/ui/Button/Button';
import { InputSearch } from '@/ui/InputSearch/InputSearch';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { PageHead } from '@/ui/PageHead/PageHead';
import { showAlert } from '@/utils/network';

import cls from './CreditRequests.module.scss';

export const CreditRequestsPageWrap: React.FC = () => {
  const { t } = useTranslation();

  const [formData, formDataLoading, loadFormData] = useCreditForm();

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const [hasNotViewed, setHasNotViewed] = useState(false);
  const [markAllLoading, setMarkAllLoading] = useState(false);
  const [sortId, setSortId] = useState<Nullable<number>>(null);
  const [search, setSearch] = useState('');
  const [updateKey, setUpdateKey] = useState(0);

  const sortFilters = useMemo(
    () => [
      {
        id: 1,
        name: t('sortLabel.from_new_to_old')
      },
      {
        id: 2,
        name: t('sortLabel.from_old_to_new')
      }
    ],
    [t]
  );

  const onMarkAllClick = async () => {
    setMarkAllLoading(true);

    try {
      await markCreditRequestsAsViewed({ mark_all: true });
      setHasNotViewed(false);
      setUpdateKey((p) => p + 1);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkAllLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    try {
      setSettingsOpen(false);
      loadFormData();
    } catch (error) {
      showAlert({ error });
    }
  };

  return (
    <>
      <LayoutHeader title={t('credits.req.title')} />
      <PageHead title={t('pageTitle', { title: t('credits.req.title') })} />

      {!formDataLoading && !!formData && !formData?.credits_available && (
        <CreditRequestsOffState openSettings={() => setSettingsOpen(true)} />
      )}

      <div className={cls.toolbar}>
        <div className={cls.search}>
          <InputSearch
            value={search}
            onChange={setSearch}
            placeholder={t('common.search')}
            showClear
            compact
            outlined
          />
        </div>
        <div className={cls.select}>
          <SortSelect
            sortFilters={sortFilters}
            sortId={sortId}
            setSortId={setSortId}
          />
        </div>

        {hasNotViewed && (
          <Button
            onClick={onMarkAllClick}
            loading={markAllLoading}
            disabled={markAllLoading}
            variant="outline"
            color="black"
            size="compact"
            gap={4}
          >
            <MarkIcon />
            {t('credits.req.markAll')}
          </Button>
        )}

        <Button
          variant="outline"
          color="black"
          size="compact"
          gap={4}
          onClick={() => setSettingsOpen(true)}
        >
          <SettingsIcon />
          {t('credits.req.settings')}
        </Button>
      </div>

      <div className={cls.wrap}>
        <CreditRequestsPage
          search={search}
          sortId={sortId}
          updateKey={updateKey}
          setHasNotViewed={setHasNotViewed}
        />
      </div>

      <AsideModal
        name="callback-settings-modal"
        title={t('credits.req.title')}
        isOpen={isSettingsOpen}
        close={() => setSettingsOpen(false)}
        containerClass={cls.modal}
      >
        <CreditForm onFormSaved={handleSaveSettings} />
      </AsideModal>
    </>
  );
};
