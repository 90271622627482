import { Nullable, PhotoType } from '@/app/types';
import { CategoryType } from '@/modules/category/types';
import { EmirateType } from '@/modules/emirate/types';
import { RegSpec } from '@/modules/regional-specs/types';
import { AdvertStatus } from '@/modules/showroom/advert/types';
import { UpdateAdvertContact } from '@/modules/showroom/advert/update/types';
import { VrpType, VrpPlateCode } from '@/modules/showroom/advert/vrp/types';
import {
  TransportBody,
  TransportBrand,
  TransportColor,
  TransportDriveUnit,
  TransportEngine,
  TransportGeneration,
  TransportModel,
  TransportModification,
  TransportOwner,
  TransportTransmission,
  TransportType,
  TransportYear
} from '@/modules/transport/types';
import { GetVinInfoRes } from '@/modules/vin/types';

export interface CreateAdvertReq {
  category_id: Nullable<number>;
  type_id: Nullable<number>;
  emirate_id: Nullable<number>;
  vrp_type_id: Nullable<number>;
  vrp_plate_code_id: Nullable<number>;
  vrp_plate_number: Nullable<string>;
  vrp_image_url: Nullable<string>;
}

export interface CreateAdvertRes {
  id: number;
  category: CategoryType;
  status: AdvertStatus;
  count_view: number;
  url: string;
  type: Nullable<TransportType>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_code: Nullable<VrpPlateCode>;
  vrp_plate_emirate: Nullable<EmirateType>;
  vrp_plate_number: Nullable<string>;
  vrp_image_url: Nullable<string>;
}

export enum SpecStep {
  year = 'yearOfIssue',
  gen = 'generation',
  body = 'body',
  engine = 'engine',
  drive = 'driveUnit',
  transmission = 'transmission',
  mod = 'modification',
  color = 'color',
  interiorColor = 'interiorColor'
}

// Store slices
export interface SpecsSlice {
  year: Nullable<TransportYear>;
  setYear: (year: Nullable<TransportYear>) => void;
  generation: Nullable<TransportGeneration>;
  setGeneration: (generation: Nullable<TransportGeneration>) => void;
  body: Nullable<TransportBody>;
  setBody: (body: Nullable<TransportBody>) => void;
  engine: Nullable<TransportEngine>;
  setEngine: (engine: Nullable<TransportEngine>) => void;
  drive: Nullable<TransportDriveUnit>;
  setDrive: (drive: Nullable<TransportDriveUnit>) => void;
  transmission: Nullable<TransportTransmission>;
  setTransmission: (transmission: Nullable<TransportTransmission>) => void;
  modification: Nullable<TransportModification>;
  setModification: (modification: Nullable<TransportModification>) => void;
  color: Nullable<TransportColor>;
  setColor: (color: Nullable<TransportColor>) => void;
  mileage: number;
  setMileage: (mileage: number) => void;
  isMileageFilled: boolean;
  setMileageFilled: (isMileageFilled: boolean) => void;
  isSpecsFilled: boolean;
  setSpecsFilled: (isSpecsFilled: boolean) => void;
  step: Nullable<SpecStep>;
  setStep: (step: Nullable<SpecStep>) => void;
}

export interface CreateAdvertSlice {
  showPageLeaveWarn: boolean;
  setShowPageLeaveWarn: (showPageLeaveWarn: boolean) => void;
  lastSectionVisible: boolean;
  setLastSectionVisible: (lastSectionVisible: boolean) => void;
  advertId: Nullable<number>;
  setAdvertId: (advertId: Nullable<number>) => void;
  type: Nullable<TransportType>;
  setType: (type: Nullable<TransportType>) => void;
  vin: string;
  setVin: (vin: string) => void;
  isDecoding: boolean;
  decodeVin: (vin: string) => Promise<void>;
  decodeInfo: Nullable<GetVinInfoRes>;
  isVinSuggestFilled: boolean;
  setVinSuggestFilled: (isVinSuggestFilled: boolean) => void;
  isVinFilled: boolean;
  setVinFilled: (isVinFilled: boolean) => void;
  resetBrandKey: number;
  brand: Nullable<TransportBrand>;
  setBrand: (brand: Nullable<TransportBrand>) => void;
  model: Nullable<TransportModel>;
  setModel: (model: Nullable<TransportModel>) => void;
  owner: Nullable<TransportOwner>;
  setOwner: (owner: Nullable<TransportOwner>) => void;
  isOwnerFilled: boolean;
  setOwnerFilled: (isOwnerFilled: boolean) => void;
  photos: PhotoType[];
  setPhotos: (photos: PhotoType[]) => void;
  addPhotos: (photos: PhotoType[]) => void;
  removePhoto: (url: PhotoType['photo_url']) => void;
  replaceLocalPhoto: (uploaded: PhotoType, localUrl: string) => void;
  isPhotosFilled: boolean;
  setPhotosFilled: (isPhotosFilled: boolean) => void;
  description: string;
  setDescription: (description: string) => void;
  isDescrGenerating: boolean;
  setDescrGenerating: (isDescrGenerating: boolean) => void;
  isDescriptionFilled: boolean;
  setDescriptionFilled: (isDescriptionFilled: boolean) => void;
  broken: Nullable<boolean>;
  setBroken: (broken: Nullable<boolean>) => void;
  isBrokenFilled: boolean;
  setBrokenFilled: (isBrokenFilled: boolean) => void;
  isCustomComplectation: boolean;
  setCustomComplectation: (isCustomComplectation: boolean) => void;
  complectationId: Nullable<number>;
  setComplectationId: (complectationId: Nullable<number>) => void;
  complectationOptionsItemsIds: number[];
  setComplectationOptionsItemsIds: (
    complectationOptionsItemsIds: number[]
  ) => void;
  isComplectationFilled: boolean;
  setComplectationFilled: (isComplectationFilled: boolean) => void;
  regSpecs: Nullable<RegSpec>;
  setRegSpecs: (regSpecs: Nullable<RegSpec>) => void;
  isRegSpecsFilled: boolean;
  setRegSpecsFilled: (isRegSpecsFilled: boolean) => void;
  contacts: Nullable<UpdateAdvertContact[]>;
  setContacts: (contacts: Nullable<UpdateAdvertContact[]>) => void;
  isPhoneFilled: boolean;
  setPhoneFilled: (isPhoneFilled: boolean) => void;
  chatOnly: boolean;
  setChatOnly: (chatOnly: boolean) => void;
  disableChat: boolean;
  setDisableChat: (disableChat: boolean) => void;
  addressId: Nullable<number>;
  setAddressId: (addressId: Nullable<number>) => void;
  isAddressFilled: boolean;
  setAddressFilled: (isAddressFilled: boolean) => void;
  stickers: number[];
  setStickers: (photos: number[]) => void;
  promotionPackageId: Nullable<number>;
  setPromotionPackageId: (promotionPackageId: Nullable<number>) => void;
  price: number;
  setPrice: (price: number) => void;
  discount: number;
  setDiscount: (discount: number) => void;
  discountTypes: number[];
  setDiscountTypes: (discountTypes: number[]) => void;
  canExchange: boolean;
  setCanExchange: (canExchange: boolean) => void;
  isPriceFilled: boolean;
  setPriceFilled: (isPriceFilled: boolean) => void;
  warranty: string;
  setWarranty: (w: string) => void;
  isWarrantyFilled: boolean;
  setWarrantyFilled: (isWarrantyFilled: boolean) => void;
  resetAll: (resetBrand?: boolean) => void;
  autoExtension: boolean;
  setAutoExtension: (autoExtension: boolean) => void;
}

export type CreateAdvertStore = CreateAdvertSlice & SpecsSlice;

export type VinReadRes = {
  vin: string;
};
