import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';

import cls from './TimerLabel.module.scss';

interface Props {
  targetDate: number;
}

export const TimerLabel: React.FC<Props> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState<number>(
    differenceInSeconds(new Date(targetDate * 1000), new Date())
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const target = new Date(targetDate * 1000);
      const remainingSeconds = differenceInSeconds(target, now);
      setTimeLeft(remainingSeconds > 0 ? remainingSeconds : 0);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return <span className={cls.root}>{formatTime(timeLeft)}</span>;
};
