export function ArrowIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.31219 11.9836C3.31219 12.2878 3.44837 12.5908 3.6804 12.8133L9.69141 18.8126C9.93399 19.0455 10.2127 19.1604 10.4946 19.1604C11.1262 19.1604 11.5859 18.702 11.5859 18.086C11.5859 17.7745 11.468 17.4968 11.2584 17.299L9.55265 15.5515L5.89334 12.2463L5.53897 12.8894L8.86498 13.1079H19.552C20.2252 13.1079 20.6878 12.644 20.6878 11.9836C20.6878 11.3231 20.2252 10.8593 19.552 10.8593H8.86498L5.53897 11.0777L5.89334 11.7305L9.55265 8.41569L11.2584 6.66608C11.468 6.46827 11.5859 6.19054 11.5859 5.87906C11.5859 5.26312 11.1262 4.80469 10.4946 4.80469C10.2127 4.80469 9.93399 4.91953 9.69141 5.1525L3.6804 11.1539C3.44837 11.3763 3.31219 11.6794 3.31219 11.9836Z"
        fill="currentColor"
      />
    </svg>
  );
}
