import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { ExpensesDefaultRow } from '@/modules/wallet/ExpensesDefault/ExpenseDefaultRow';
import { ExpensesDefaultSkeleon } from '@/modules/wallet/ExpensesDefault/ExpensesDefaultSkeleton';
import {
  ExpenseCategory,
  ExpensePromotion,
  StatisticsDay
} from '@/modules/wallet/types';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { getDateString } from '@/utils/date';

import cls from './ExpensesDefault.module.scss';

type Props = {
  expenses: StatisticsDay[];
  categories: ExpenseCategory[];
  promotion: ExpensePromotion[];
  isLoading?: boolean;
};

export function ExpensesDefault({
  expenses,
  categories,
  promotion,
  isLoading
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const groupedExpenses = useMemo(() => {
    const groupedItems: {
      [key: string]: StatisticsDay[];
    } = {};

    expenses.forEach((item) => {
      const date = new Date(item.created_at * 1000);
      const day = date.toISOString().split('T')[0];

      if (!groupedItems[day]) {
        groupedItems[day] = [];
      }

      groupedItems[day].push({
        ...item
      });
    });

    return Object.keys(groupedItems).map((day) => ({
      day: new Date(day),
      amount: groupedItems[day].reduce(
        (accumulator, item) => accumulator + item.amount,
        0
      ),
      items: groupedItems[day]
    }));
  }, [expenses]);

  const categoriesObj = useMemo(() => {
    const mappedCategories: Record<string, ExpenseCategory> = {};

    categories.forEach(
      (category) => (mappedCategories[category.id.toString()] = category)
    );

    return mappedCategories;
  }, [categories]);

  const promotionsObj = useMemo(() => {
    const mappedPromotions: Record<string, ExpensePromotion> = {};

    promotion.forEach(
      (promo) => (mappedPromotions[promo.id.toString()] = promo)
    );

    return mappedPromotions;
  }, [promotion]);

  return (
    <div className={cls.root}>
      {isLoading && <ExpensesDefaultSkeleon />}

      {!isLoading &&
        groupedExpenses.map((expense, i) => (
          <div key={i} className={cls.date_row}>
            <p className={cls.date}>
              {t('common.dotSeparated', {
                first: getDateString(expense.day, lang),
                second: t('priceCurrency', {
                  price: expense.amount,
                  currency: CURRENCY_DISPLAY.aed
                })
              })}
            </p>

            <div className={cls.adverts}>
              {expense.items.map((row, k) => (
                <Fragment key={k}>
                  {row.advertisement && (
                    <ExpensesDefaultRow
                      expense={row}
                      advert={row.advertisement}
                      categories={categoriesObj}
                      promotion={promotionsObj}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}
