export function ArrowIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93444 18.1227C6.17112 20.1667 5.69153 21.6025 5.49568 22.4302C4.88085 25.0286 4.4338 25.6139 6.72532 24.379C9.01684 23.1441 20.1104 17.0271 22.5855 15.6676C25.8118 13.8955 25.8549 14.034 22.4124 12.1524C19.7904 10.7194 8.82689 4.76183 6.72532 3.59207C4.62375 2.42231 4.88085 2.94248 5.49568 5.54084C5.69407 6.37929 6.18062 7.82797 6.95534 9.88688C7.49471 11.3203 8.74065 12.3701 10.2448 12.6586L16.6775 13.8921C16.7376 13.9041 16.7767 13.962 16.7646 14.0216C16.7558 14.0651 16.7214 14.0992 16.6775 14.1079L10.2303 15.3402C8.72124 15.6287 7.47194 16.6834 6.93444 18.1227Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function QuickReplyIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9991 22C17.4773 22 22 17.4707 22 12C22 6.52137 17.4694 2 11.9912 2C6.52272 2 2 6.52137 2 12C2 17.4707 6.53066 22 11.9991 22ZM11.9991 20.0302C7.54339 20.0302 3.97762 16.4561 3.97762 12C3.97762 7.54387 7.53545 3.96986 11.9912 3.96986C16.4469 3.96986 20.0303 7.54387 20.0303 12C20.0303 16.4561 16.4548 20.0302 11.9991 20.0302Z"
        fill="currentColor"
      />
      <path
        d="M7.80359 12.6478C7.80359 12.9055 8.01113 13.0937 8.27849 13.0937H11.4607L9.76264 17.6278C9.51987 18.2771 10.213 18.6403 10.6439 18.1125L15.8632 11.6159C15.9732 11.4961 16.0313 11.3536 16.0313 11.2208C16.0313 10.9631 15.8238 10.7732 15.5564 10.7732H12.3742L14.0722 6.23903C14.315 5.59157 13.6219 5.22834 13.1909 5.74647L7.97164 12.2448C7.86167 12.3725 7.80359 12.5132 7.80359 12.6478Z"
        fill="currentColor"
      />
    </svg>
  );
}
