import { useTranslation } from 'react-i18next';

import { SortFilter } from '@/modules/showroom/advert/my/types';
import { AddressFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/AddressFilter';
import { AutoFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/AutoFilter';
import { EffectivenessFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/EffectivenessFilter';
import { PriceFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/PriceFilter';
import { TypeFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/TypeFilter';
import { VinFilter } from '@/modules/showroom/advert/search/SearchAdverts/Filters/VinFilter';
import { SortSelect } from '@/modules/showroom/advert/search/SearchAdverts/SortSelect/SortSelect';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

import cls from './Filters.module.scss';

type Props = {
  sortFilters: SortFilter[];
  showReset: boolean;
  onResetClick: () => void;
};

export function Filters({ sortFilters, showReset, onResetClick }: Props) {
  const { t } = useTranslation();

  const updateAutoFilter = useSearchStore((s) => s.updateAutoFilter);
  const autoFilters = useSearchStore((s) => s.autoFilters);
  const min = useSearchStore((s) => s.priceMin);
  const setMin = useSearchStore((s) => s.setPriceMin);
  const max = useSearchStore((s) => s.priceMax);
  const setMax = useSearchStore((s) => s.setPriceMax);
  const sortId = useSearchStore((s) => s.sortId);
  const setSortId = useSearchStore((s) => s.setSortId);
  const searchText = useSearchStore((s) => s.searchText);
  const setSearchText = useSearchStore((s) => s.setSearchText);

  return (
    <div className={cls.root}>
      <div className={cls.search}>
        <InputSearch
          value={searchText}
          onChange={setSearchText}
          placeholder={t('common.search')}
          showClear
          compact
          outlined
        />
      </div>
      <ul className={cls.list}>
        <li className={cls.li}>
          <AutoFilter
            updateAutoFilter={updateAutoFilter}
            autoFilters={autoFilters}
          />
        </li>
        <li className={cls.li}>
          <PriceFilter min={min} max={max} setMin={setMin} setMax={setMax} />
        </li>
        <li className={cls.li}>
          <EffectivenessFilter />
        </li>
        <li className={cls.li}>
          <TypeFilter />
        </li>
        <li className={cls.li_input}>
          <VinFilter />
        </li>
        <li className={cls.li}>
          <AddressFilter />
        </li>
        {showReset && (
          <li className={cls.li}>
            <button
              className={cls.reset_btn}
              onClick={onResetClick}
              type="button"
            >
              {t('common.reset')}
            </button>
          </li>
        )}
      </ul>
      <div className={cls.select}>
        <SortSelect
          sortFilters={sortFilters}
          sortId={sortId}
          setSortId={setSortId}
        />
      </div>
    </div>
  );
}
