import { useEffect } from 'react';
import { Toaster, toast } from 'sonner';

import { useAlertStore } from './alert-store';

const DURATION = 6000;

export function Alert() {
  const data = useAlertStore((s) => s.data);
  const isOpen = useAlertStore((s) => s.isOpen);

  useEffect(() => {
    if (isOpen && data) {
      if (data.action) {
        toast[data.type](data.text, { action: data.action });
      } else if (data.promise) {
        toast.promise(data.promise, {
          loading: data?.promiseTexts?.loadingText,
          success: data?.promiseTexts?.successText,
          error: data?.promiseTexts?.errorText
        });
      } else {
        toast[data.type](data.text);
      }
    }
  }, [data, isOpen]);

  const richColors =
    data && typeof data.richColors === 'boolean' ? data.richColors : true;

  return (
    <Toaster position="top-right" duration={DURATION} richColors={richColors} />
  );
}
