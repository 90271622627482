import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@/ui/Skeleton';

import cls from './FiltersList.module.scss';
import { skeletons, Props } from './helpers';
import { RemoveIcon } from './icons';
import { List } from './List';

export function FiltersList({
  showImage,
  label,
  list,
  onClick,
  search,
  activeId,
  loading,
  allButtonText,
  onAllClick,
  showArrow,
  selectStyle,
  defaultAllButtonStyle,
  showCheckIcon
}: Props) {
  const { t } = useTranslation();

  const popularList = list.filter((v) => 'is_popular' in v && v.is_popular);
  const filteredList = useMemo(() => {
    if (!search) return list;
    const lowSearch = search.toLowerCase().trim();
    return list.filter((b) => {
      const name = b.name.toLowerCase();
      const hasKeywords = 'keywords_string' in b && !!b.keywords_string;
      const keywords = hasKeywords
        ? b.keywords_string?.toLowerCase() || ''
        : '';
      return name.includes(lowSearch) || keywords.includes(lowSearch);
    });
  }, [list, search]);

  const showEmptySearch = !!search && filteredList.length <= 0;
  const hasPopular = popularList.length > 0;
  const showAllButton = !!allButtonText && !!onAllClick;
  const isDefaultAllButton = selectStyle || defaultAllButtonStyle;

  return (
    <div>
      {label && <b className={cls.label}>{label}</b>}

      {loading && (
        <>
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton
                  width="100%"
                  height={36}
                  style={{ borderRadius: 8, marginBottom: 4 }}
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {!loading && showAllButton && (hasPopular ? !search : true) && (
        <button
          className={cn(cls.row, {
            [cls.row_all_select]: isDefaultAllButton
          })}
          type="button"
          onClick={onAllClick}
        >
          {isDefaultAllButton && <RemoveIcon />}
          {allButtonText}
        </button>
      )}

      {!loading && (search || !hasPopular) && (
        <>
          {showEmptySearch ? (
            <p className={cls.empty}>
              {t('search.queryNotFound', { query: search })}
            </p>
          ) : (
            <List
              showImage={showImage}
              selectStyle={selectStyle}
              activeId={activeId}
              list={filteredList}
              onClick={onClick}
              showArrow={showArrow}
              showCheckIcon={showCheckIcon}
            />
          )}
        </>
      )}

      {hasPopular && !loading && !search && (
        <>
          <span className={cls.subtitle}>{t('common.popular')}</span>
          <List
            showImage={showImage}
            selectStyle={selectStyle}
            activeId={activeId}
            list={popularList}
            onClick={onClick}
            showArrow={showArrow}
          />

          <span className={cls.subtitle}>{t('common.all')}</span>
          <List
            showImage={showImage}
            selectStyle={selectStyle}
            activeId={activeId}
            list={list}
            onClick={onClick}
            showArrow={showArrow}
          />
        </>
      )}
    </div>
  );
}
