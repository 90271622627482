import { Skeleton } from '@/ui/Skeleton';

import cls from './Form.module.scss';

export function FormSkeleton() {
  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <Skeleton width={28} height={28} />

        <div className={cls.textarea}>
          <Skeleton width={130} height={28} />
        </div>

        <Skeleton width={28} height={28} />
      </div>
    </div>
  );
}
