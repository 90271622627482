export function UnreadIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.67697 9.28369C3.36858 9.00716 2.89441 9.03298 2.61788 9.34137C2.34135 9.64976 2.36718 10.1239 2.67557 10.4005L3.67697 9.28369ZM6.36982 12.7057L5.86911 13.2641C6.15165 13.5174 6.57891 13.5198 6.86426 13.2696L6.36982 12.7057ZM15.3178 5.85788C15.6292 5.58481 15.6603 5.11096 15.3873 4.7995C15.1142 4.48805 14.6403 4.45693 14.3289 4.73001L15.3178 5.85788ZM2.67557 10.4005L5.86911 13.2641L6.87052 12.1473L3.67697 9.28369L2.67557 10.4005ZM6.86426 13.2696L15.3178 5.85788L14.3289 4.73001L5.87538 12.1418L6.86426 13.2696Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ReadIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.57033 9.22719C1.26752 8.94456 0.792928 8.96093 0.510302 9.26374C0.227677 9.56655 0.244042 10.0411 0.546855 10.3238L1.57033 9.22719ZM4.01345 12.5333L3.50171 13.0816C3.78987 13.3506 4.23703 13.3506 4.52519 13.0816L4.01345 12.5333ZM12.2817 5.84224C12.5845 5.55961 12.6009 5.08502 12.3182 4.78221C12.0356 4.47939 11.561 4.46303 11.2582 4.74565L12.2817 5.84224ZM9.18445 12.7057L8.67271 13.254C8.96087 13.5229 9.40803 13.5229 9.69619 13.254L9.18445 12.7057ZM17.4527 6.0146C17.7555 5.73198 17.7719 5.25739 17.4892 4.95457C17.2066 4.65176 16.732 4.6354 16.4292 4.91802L17.4527 6.0146ZM8.7728 11.2956C8.46998 11.013 7.99539 11.0293 7.71277 11.3321C7.43014 11.635 7.44651 12.1095 7.74932 12.3922L8.7728 11.2956ZM0.546855 10.3238L3.50171 13.0816L4.52519 11.9851L1.57033 9.22719L0.546855 10.3238ZM4.52519 13.0816L12.2817 5.84224L11.2582 4.74565L3.50171 11.9851L4.52519 13.0816ZM9.69619 13.254L17.4527 6.0146L16.4292 4.91802L8.67271 12.1574L9.69619 13.254ZM9.69619 12.1574L8.7728 11.2956L7.74932 12.3922L8.67271 13.254L9.69619 12.1574Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function OfferIcon(): JSX.Element {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7558_100388)">
        <path
          d="M0.884283 10.021C0.884283 10.947 1.49303 11.4525 2.53389 11.4525H5.34848V6.83915H0.884283V10.021ZM6.58373 11.4525H9.40432C10.4392 11.4525 11.0539 10.947 11.0539 10.021V6.83915H6.58373V11.4525ZM0.0793457 5.22882C0.0793457 5.83943 0.476765 6.13513 1.14891 6.13513H5.34848V3.04658H4.47554C3.58147 3.04658 3.06724 2.56872 3.06724 1.99474C3.06724 1.44114 3.4891 1.09248 4.12578 1.09248C4.79904 1.09248 5.34848 1.58681 5.34848 2.31791V3.04658H6.58373V2.31791C6.58373 1.58681 7.13699 1.09248 7.80836 1.09248C8.445 1.09248 8.86686 1.44114 8.86686 1.99474C8.86686 2.56872 8.35479 3.04658 7.45858 3.04658H6.58373V6.13513H10.7874C11.4595 6.13513 11.8588 5.83943 11.8588 5.22882V4.11086C11.8588 3.39153 11.4046 3.04658 10.6111 3.04658H9.56758C9.90949 2.74261 10.1162 2.33349 10.1162 1.86003C10.1162 0.774511 9.15121 0 7.94373 0C7.03537 0 6.25494 0.469054 5.96611 1.29523C5.67919 0.469054 4.90089 0 3.98847 0C2.78696 0 1.8179 0.774511 1.8179 1.86003C1.8179 2.33349 2.02462 2.74261 2.37059 3.04658H1.32707C0.553949 3.04658 0.0793457 3.39153 0.0793457 4.11086V5.22882Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7558_100388">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.0793457)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
