import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { useLang } from '@/hooks/useLang';
import { getOfferPreviewMessage, sendOffers } from '@/modules/offers/api';
import { OfferPreviewMessage } from '@/modules/offers/OfferModal/OfferPreviewMessage/OfferPreviewMessage';
import { OfferSendButton } from '@/modules/offers/OfferModal/OfferSendButton/OfferSendButton';
import { PromoOfferReq } from '@/modules/offers/types';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';
import { Input } from '@/ui/Input/Input';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { PrimarySelect } from '@/ui/PrimarySelect/PrimarySelect';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import {
  maxLength,
  numberInRange,
  numberWithSpaces,
  onlyNumbers,
  pluralKey
} from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './OfferModal.module.scss';

enum DiscountType {
  percentage = 'percentage',
  cash = 'cash'
}

const discountTypeLabel = {
  [DiscountType.percentage]: '%',
  [DiscountType.cash]: CURRENCY_DISPLAY.aed
};
const availableOfferHours = [2, 12, 24, 48, 72];
const MAX_PROMOCODE_LEN = 24;
const DEFAULT_HOURS_OPTION_ID = '24';

type Props = {
  isOpen: boolean;
  close: () => void;
  advert: MyAdvert;
};

export const OfferModal: React.FC<Props> = ({ isOpen, close, advert }) => {
  const [locale] = useLang();
  const { t } = useTranslation();

  const [selectedDiscountType, setSelectedDiscountType] =
    useState<DiscountType>(DiscountType.percentage);
  const [percentageDiscount, setPercentageDiscount] = useState('');
  const [cashDiscount, setCashDiscount] = useState('');
  const [offerHours, setOfferHours] = useState(0);
  const [promocode, setPromocode] = useState('');
  const [previewMessage, setPreviewMessage] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [selectedHoursOptions, setSelectedHoursOption] = useState<OptionI[]>(
    []
  );
  const offerHoursOptions = useMemo<OptionI[]>(
    () =>
      availableOfferHours.map((hours) => ({
        id: hours.toString(),
        text: !hours
          ? t('offer.notSelectedHours')
          : pluralKey(
              hours,
              t('offer.hoursPlural.one', {
                hours
              }),
              t('offer.hoursPlural.few', {
                hours
              }),
              t('offer.hoursPlural.many', {
                hours
              })
            )
      })),
    [t]
  );

  const promoOfferReq = useMemo<PromoOfferReq>(
    () => ({
      discount: {
        percentage: percentageDiscount ? Number(percentageDiscount) : undefined,
        aed: cashDiscount ? Number(onlyNumbers(cashDiscount)) : undefined
      },
      discount_period: offerHours,
      promo_code: promocode || undefined
    }),
    [cashDiscount, offerHours, percentageDiscount, promocode]
  );

  const debouncedOfferHoursOptions = useDebounce(promoOfferReq, 500);

  const loadOfferPreviewMessage = async () => {
    const discountParams = debouncedOfferHoursOptions.discount;
    if (
      (!discountParams.percentage && !discountParams.aed) ||
      !debouncedOfferHoursOptions.discount_period
    ) {
      setPreviewMessage('');
      return;
    }

    setLoading(true);
    try {
      const res = await getOfferPreviewMessage(debouncedOfferHoursOptions);
      setPreviewMessage(res?.data?.message || '');
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleSendOffers = async () => {
    if (!previewMessage) return;

    setLoading(true);
    try {
      await sendOffers(
        { advertisement_id: advert.id },
        { discount: promoOfferReq }
      );
      showAlert({ text: t('offer.offersSended') });
      close();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCashDiscount('');
    setPercentageDiscount('');
  }, [selectedDiscountType]);

  useEffect(() => {
    if (!offerHoursOptions.length) return;

    const defaultHoursOption = offerHoursOptions.find(
      (hour) => hour.id === DEFAULT_HOURS_OPTION_ID
    );
    defaultHoursOption && setSelectedHoursOption([defaultHoursOption]);
  }, [offerHoursOptions]);

  useEffect(() => {
    if (!selectedHoursOptions.length || !selectedHoursOptions[0].id) {
      setOfferHours(0);
      return;
    }

    setOfferHours(Number(selectedHoursOptions[0].id));
  }, [selectedHoursOptions]);

  useEffect(() => {
    loadOfferPreviewMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOfferHoursOptions]);

  return (
    <AsideModal
      name="offer-modal"
      title={t('offer.title')}
      isOpen={isOpen}
      close={close}
    >
      <div className={cls.root}>
        <p className={cls.description}>{t('offer.description')}</p>

        <div className={cls.settings}>
          <ul className={cls.chips}>
            {Object.values(DiscountType).map((discountType) => (
              <li key={discountType}>
                <CheckBtn
                  onClick={() => setSelectedDiscountType(discountType)}
                  active={discountType === selectedDiscountType}
                >
                  {t(`offer.discount`, {
                    type: discountTypeLabel[discountType]
                  })}
                </CheckBtn>
              </li>
            ))}
          </ul>

          <div className={cls.discount}>
            {selectedDiscountType === DiscountType.percentage && (
              <Input
                value={percentageDiscount}
                onChange={(e) =>
                  setPercentageDiscount(
                    numberInRange(e.currentTarget.value, 0, 100)
                  )
                }
                label={t('offer.discountSize', {
                  type: discountTypeLabel[DiscountType.percentage]
                })}
              />
            )}

            {selectedDiscountType === DiscountType.cash && (
              <Input
                value={
                  cashDiscount
                    ? numberWithSpaces(
                        Number(onlyNumbers(cashDiscount)),
                        locale
                      )
                    : ''
                }
                onChange={(e) =>
                  setCashDiscount(
                    numberInRange(e.currentTarget.value, 0, advert.price || 0)
                  )
                }
                label={t('offer.discountSize', {
                  type: discountTypeLabel[DiscountType.cash]
                })}
              />
            )}

            <PrimarySelect
              name="offer-time-select"
              value={selectedHoursOptions}
              onChange={(hoursOption) => setSelectedHoursOption(hoursOption)}
              options={offerHoursOptions}
              multiple={false}
              dropdownFullWidth
              large
            />
          </div>

          <div className={cls.promocode}>
            <Input
              label={t('offer.promocode')}
              value={promocode}
              onChange={(e) =>
                setPromocode(
                  maxLength(e.currentTarget.value, MAX_PROMOCODE_LEN)
                )
              }
            />
            <small>
              {t('common.slashSeparated', {
                first: promocode.length,
                second: MAX_PROMOCODE_LEN
              })}
            </small>
          </div>

          {previewMessage && <OfferPreviewMessage message={previewMessage} />}
        </div>

        <OfferSendButton
          advertId={advert.id}
          onClickSend={handleSendOffers}
          isRequiredParamsSetted={!!previewMessage}
          isParamsLoading={isLoading}
        />
      </div>
    </AsideModal>
  );
};
