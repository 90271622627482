import type { ApexOptions } from 'apexcharts';

import { CommonObj } from '@/app/types';
import { EXPENSE_CATEGORY_COLORS_MAP } from '@/modules/wallet/expense-helpers';
import { ADV_CUR } from '@/utils/consts';
import { getDateString } from '@/utils/date';

export function getApexOptions(
  xCategories: unknown[],
  categories: CommonObj[],
  lang: string,
  isDark: boolean
): ApexOptions {
  return {
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      parentHeightOffset: 16
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    colors: categories
      .sort((a, b) => a.id - b.id)
      .map((c) => EXPENSE_CATEGORY_COLORS_MAP[c.id]),
    dataLabels: {
      enabled: false
    },
    xaxis: {
      axisBorder: {
        color: 'var(--thm-grey-1)'
      },
      axisTicks: {
        color: 'var(--thm-grey-1)'
      },
      tooltip: {
        enabled: false
      },
      type: 'datetime',
      categories: xCategories,
      labels: {
        trim: true,
        formatter: (value) =>
          getDateString(new Date(value), lang, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          })
      }
    },
    yaxis: {
      labels: {
        show: false,
        formatter: (v: number) => `${Number(v)}`
      }
    },
    tooltip: {
      theme: isDark ? 'dark' : 'light',
      y: {
        formatter: (v: number) => `${Number(v)} ${ADV_CUR}`,
        title: {
          formatter: (seriesName) => seriesName
        }
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    fill: {
      opacity: 1,
      gradient: {
        shade: isDark ? 'dark' : 'light'
      }
    }
  };
}
