import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCommonStore } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { useDefaultPeriod } from '@/hooks/useDefaultPeriod';
import { useLang } from '@/hooks/useLang';
import { BalanceCard } from '@/modules/wallet/BalanceCard/BalanceCard';
import { DepositHistory } from '@/modules/wallet/DepositHistory/DepositHistory';
import { ExpensesAdverts } from '@/modules/wallet/ExpensesAdverts/ExpensesAdverts';
import { ExpensesDays } from '@/modules/wallet/ExpensesDays/ExpensesDays';
import { ExpensesDefault } from '@/modules/wallet/ExpensesDefault/ExpensesDefault';
import { BalanceFilters } from '@/modules/wallet/Filters/BalanceFilters';
import { ExpensesTypeFilter } from '@/modules/wallet/Filters/ExpensesTypeFilter';
import { WalletFilters } from '@/modules/wallet/Filters/WalletFilters';
import {
  useExpensesDetailStatistics,
  useExpensesDetailStatisticsV2,
  WalletCategories,
  WalletCategoriesType,
  WalletFilterType
} from '@/modules/wallet/hooks';
import {
  ExpensesDetailStatisticsReq,
  ExpenseType
} from '@/modules/wallet/types';
import { WalletGraphWrap } from '@/modules/wallet/WalletGraph/WalletGraphWrap';
import { Card } from '@/ui/Card/Card';
import { DatesFilter } from '@/ui/Filters/DatesFilter';
import { InputSearch } from '@/ui/InputSearch/InputSearch';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { useQuery } from '@/utils/query';

import cls from './WalletPage.module.scss';

const MIN_GRAPH_WORK_SIZE = 480;

export function WalletPage() {
  const { t } = useTranslation();
  const [lang] = useLang();
  const defaultPeriod = useDefaultPeriod();
  const windowWidth = useCommonStore((s) => s.windowWidth);

  const [activeTab, setActiveTab] = useState<WalletCategories>(
    WalletCategories.debit
  );

  const showGraph =
    windowWidth > MIN_GRAPH_WORK_SIZE && activeTab === WalletCategories.debit;

  // Wallet filters
  const onWalletFilterClick = (tab: WalletCategoriesType) => {
    setActiveTab(tab.id);
  };

  // Filter type
  const [filterType, setFilterType] = useState<WalletFilterType>(
    WalletFilterType.default
  );

  // Expenses type
  const [expensesType, setExpensesType] = useState<Nullable<ExpenseType>>();

  // Vin
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryHandled = useRef(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 800);

  const query = useQuery();
  useEffect(() => {
    if (
      query &&
      query.vin &&
      typeof query.vin === 'string' &&
      !searchQueryHandled.current
    ) {
      setSearchQuery(query.vin);
      setActiveTab(WalletCategories.debit);
      setFilterType(WalletFilterType.adverts);
      searchQueryHandled.current = true;
    }
  }, [query]);

  // Dates
  const [from, setFrom] = useState<Date>(defaultPeriod.from);
  const [to, setTo] = useState<Date>(defaultPeriod.to);
  const onDatesChange = (fromDate: Date, toDate: Date) => {
    toDate.setHours(23, 59, 59);
    setFrom(fromDate);
    setTo(toDate);
  };
  const fromPeriodUnix = useMemo(
    () => Math.round(from.getTime() / 1000),
    [from]
  );
  const toPeriodUnix = useMemo(() => Math.round(to.getTime() / 1000), [to]);

  // Expenses data
  const expensesReq = useMemo<ExpensesDetailStatisticsReq>(
    () => ({
      period_start: fromPeriodUnix,
      period_end: toPeriodUnix,
      search: debouncedSearchQuery || undefined,
      expense_type: expensesType || undefined
    }),
    [debouncedSearchQuery, expensesType, fromPeriodUnix, toPeriodUnix]
  );
  const [expensesData, expensesLoading] = useExpensesDetailStatistics(
    expensesReq,
    lang
  );
  const [daysData, daysLoading] = useExpensesDetailStatisticsV2(
    expensesReq,
    lang
  );

  return (
    <>
      <LayoutHeader title={t('wallet.title')} />
      <PageHead title={t('pageTitle', { title: t('wallet.title') })} />

      <div className={cls.root}>
        <div className={cls.tabs}>
          <WalletFilters onClick={onWalletFilterClick} />
        </div>

        <div className={cls.container}>
          <div className={cls.content}>
            <div
              className={cls.row}
              style={{
                marginBottom: activeTab === WalletCategories.refill ? 24 : 16
              }}
            >
              <DatesFilter from={from} to={to} onDatesChange={onDatesChange} />
            </div>

            {showGraph && (
              <Card>
                <WalletGraphWrap
                  from={from}
                  to={to}
                  data={expensesData}
                  loading={expensesLoading}
                />
              </Card>
            )}

            <div
              style={{
                display:
                  activeTab === WalletCategories.refill ? 'block' : 'none'
              }}
            >
              <DepositHistory from={from} to={to} />
            </div>
          </div>

          <aside
            className={cls.aside}
            style={{
              paddingTop: activeTab === WalletCategories.refill ? 0 : 52
            }}
          >
            <BalanceCard
              fromPeriod={fromPeriodUnix}
              toPeriod={toPeriodUnix}
              buttonSize="l"
              balanceInfo={daysData?.balance}
              loading={daysLoading}
            />
          </aside>
        </div>

        <div
          style={{
            display: activeTab === WalletCategories.debit ? 'block' : 'none'
          }}
        >
          <div className={cls.view_types}>
            <ul className={cls.filters}>
              <li className={cls.search_input}>
                <InputSearch
                  placeholder={t('wallet.searchByNameOrVIN')}
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                  compact
                  showClear
                />
              </li>
              <li className={cls.li}>
                <ExpensesTypeFilter setExpenseType={setExpensesType} />
              </li>
            </ul>

            <div className={cls.type_tabs}>
              <p>{t('wallet.groupBy')}</p>
              <BalanceFilters selected={filterType} onChange={setFilterType} />
            </div>
          </div>

          {filterType === WalletFilterType.default && expensesData && (
            <ExpensesDefault
              expenses={expensesData.expenses}
              categories={expensesData.categories}
              promotion={expensesData.promotion}
              isLoading={expensesLoading}
            />
          )}

          {filterType === WalletFilterType.services && (
            <ExpensesDays
              days={daysData?.mapped_days || []}
              loading={daysLoading}
            />
          )}

          {filterType === WalletFilterType.adverts && expensesData && (
            <div className={cls.container}>
              <ExpensesAdverts expenses={expensesData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
