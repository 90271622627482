export function InfoIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6646 14.6625C12.3159 14.6625 15.3304 11.644 15.3304 7.99795C15.3304 4.34666 12.3106 1.33334 8.65932 1.33334C5.01448 1.33334 2 4.34666 2 7.99795C2 11.644 5.01977 14.6625 8.6646 14.6625ZM8.6646 13.3497C5.69477 13.3497 3.31812 10.9678 3.31812 7.99795C3.31812 5.02811 5.68948 2.64618 8.65932 2.64618C11.6291 2.64618 14.0175 5.02811 14.0175 7.99795C14.0175 10.9678 11.6344 13.3497 8.6646 13.3497Z"
        fill="currentColor"
      />
      <path
        d="M7.53664 11.5472H10.1336C10.419 11.5472 10.6429 11.3397 10.6429 11.0508C10.6429 10.7782 10.419 10.562 10.1336 10.562H9.41479V7.5137C9.41479 7.13276 9.22435 6.88077 8.8651 6.88077H7.64984C7.36323 6.88077 7.14051 7.09819 7.14051 7.37072C7.14051 7.65966 7.36323 7.86711 7.64984 7.86711H8.30188V10.562H7.53664C7.25122 10.562 7.02734 10.7782 7.02734 11.0508C7.02734 11.3397 7.25122 11.5472 7.53664 11.5472ZM8.62396 5.88289C9.11121 5.88289 9.49523 5.49242 9.49523 5.00866C9.49523 4.51908 9.11121 4.13093 8.62396 4.13093C8.14546 4.13093 7.75384 4.51908 7.75384 5.00866C7.75384 5.49242 8.14546 5.88289 8.62396 5.88289Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.53512 9.60506C3.40564 9.73177 3.3335 9.90065 3.3335 10.0952C3.3335 10.4874 3.63774 10.7939 4.02895 10.7939C4.22563 10.7939 4.40702 10.7201 4.53421 10.5831L8.96412 6.04924H8.37499L12.8006 10.5831C12.9316 10.7212 13.1141 10.7939 13.3047 10.7939C13.6949 10.7939 14.0002 10.4874 14.0002 10.0952C14.0002 9.89957 13.9291 9.73068 13.7975 9.60506L9.21208 4.91236C9.05986 4.75133 8.8746 4.66882 8.66986 4.66666C8.46124 4.66666 8.27985 4.75025 8.12267 4.91236L3.53512 9.60506Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ArrowIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3956 7.99948C11.3934 7.7946 11.3169 7.61807 11.1547 7.4619L6.46197 2.86452C6.32532 2.73281 6.1624 2.66666 5.96563 2.66666C5.5781 2.66666 5.2666 2.97104 5.2666 3.36243C5.2666 3.55318 5.3442 3.72971 5.47856 3.86684L9.71517 7.99834L5.47856 12.1321C5.34529 12.2681 5.2666 12.4408 5.2666 12.6376C5.2666 13.0279 5.5781 13.3333 5.96563 13.3333C6.16023 13.3333 6.32532 13.2672 6.46197 13.1305L11.1547 8.53701C11.3179 8.37588 11.3956 8.20323 11.3956 7.99948Z"
        fill="currentColor"
      />
    </svg>
  );
}
