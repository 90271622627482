import { Nullable, PhotoType, PaginationReq, CommonObj } from '@/app/types';
import { CategoryType } from '@/modules/category/types';
import { EmirateType } from '@/modules/emirate/types';
import { VrpPlateCode, VrpType } from '@/modules/showroom/advert/vrp/types';
import {
  TransportBrand,
  TransportModel,
  TransportGeneration,
  TransportYear
} from '@/modules/transport/types';

export interface ReviewComment {
  id: number;
  created_at: number;
  name: Nullable<string>;
  avatar: Nullable<PhotoType>;
  comment: Nullable<string>;
}

export interface ReviewAd {
  id: number;
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<TransportGeneration>;
  year_of_issue: Nullable<TransportYear>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_number: Nullable<string>;
  vrp_plate_code: Nullable<VrpPlateCode>;
  vrp_plate_emirate: Nullable<EmirateType>;
}

export interface Review {
  id: number;
  count_star_id: number;
  deal_done: boolean;
  advertisement: ReviewAd;
  comments: {
    reviewer: ReviewComment;
    reviewed: Nullable<ReviewComment>;
  };
}

export interface ReviewsEstimate {
  star_id: 0;
  count: 0;
}

export interface GetAccountReviewsRes {
  reviews_average_score: number;
  count_reviews: number;
  estimates: ReviewsEstimate[];
  reviews: Review[];
}

export interface CreateNewReviewReq {
  text: string;
  rating: number;
  advertisement_id: number;
  how_did_it_end_answer_id: number;
  condition_answer_id: Nullable<number>;
  price_answer_id: Nullable<number>;
}

export enum ReviewStatus {
  moderation = 1,
  declined = 2,
  approved = 3
}

// My reviews
export interface MyReview extends Review {
  reviewed_user_id: number;
  status: {
    id: ReviewStatus;
    name: string;
  };
  reply_status: Nullable<{
    id: ReviewStatus;
    name: string;
  }>;
  reply_moderation_rejected_reason: Nullable<string>;
}

export interface GetMyReviewsReq {
  rating?: Nullable<number>;
  sort_filter_id?: Nullable<number>;
  category_id?: number;
  period_start?: number;
  period_end?: number;
  with_no_reply_only: boolean;
}
export interface GetMyReviewsRes {
  reviews_average_score: number;
  count_reviews: number;
  estimates: ReviewsEstimate[];
  reviews: MyReview[];
}

export type GetReviewsSortFilters = CommonObj[];

// Ads for review
export interface AdForReview {
  id: number;
  category: CategoryType;
  photos: Nullable<PhotoType[]>;
  name: Nullable<string>;
  price: Nullable<number>;
  brand: Nullable<TransportBrand>;
  model: Nullable<TransportModel>;
  generation: Nullable<TransportGeneration>;
  year_of_issue: Nullable<TransportYear>;
  vrp_type: Nullable<VrpType>;
  vrp_plate_number: Nullable<string>;
  vrp_image_url: Nullable<string>;
  vrp_plate_code: Nullable<VrpPlateCode>;
  vrp_plate_emirate: Nullable<EmirateType>;
}

export interface GetAdsForReviewReq extends PaginationReq {
  account_id: number;
}
export type GetAdsForReviewRes = AdForReview[];

// Questions for review
export interface QuestionForReview extends CommonObj {
  deal_done: Nullable<boolean>;
}
export interface GetQuestionsForReviewReq {
  ad_category_id: number;
}
export type GetQuestionsForReviewRes = QuestionForReview[];

export type QuestionAnswerForReview = QuestionForReview;
export interface GetQuestionAnswersForReviewReq
  extends GetQuestionsForReviewReq {
  questions_id: number;
}
export type GetQuestionAnswersForReviewRes = QuestionAnswerForReview[];

export interface DeleteReplyReq {
  review_id: number;
}

export interface CreateNewReviewReplyReq {
  replied_review_id: number;
  text: string;
}
