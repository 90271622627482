import { create } from 'zustand';

import { Nullable } from '@/app/types';

export type AlertAction = {
  label: string;
  onClick: () => void;
};

export type AlertType = 'success' | 'error' | 'message';
export type AlertData = {
  richColors?: boolean;
  type: AlertType;
  text: string;
  action?: AlertAction;
  promise?: Promise<VoidFunction | void>;
  promiseTexts?: {
    loadingText?: React.ReactNode;
    successText?: React.ReactNode;
    errorText?: React.ReactNode;
  };
};

interface AlertStore {
  isOpen: boolean;
  data: Nullable<AlertData>;
  show: (alertData: AlertData) => void;
}

export const useAlertStore = create<AlertStore>((set) => ({
  isOpen: false,
  data: null,
  show: (data: AlertData) => set({ data, isOpen: true })
}));
