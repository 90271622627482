import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Spinner } from '@/ui/Spinner/Spinner';

import { useChatVideos } from './helpers';
import { PlayIcon } from './icons';
import cls from './Videos.module.scss';

import attachmentsCls from '../Attachments.module.scss';

type Props = {
  chatId: number;
};

export const Videos: React.FC<Props> = ({ chatId }) => {
  const { t } = useTranslation();

  const { isAllLoaded, isLoading, data, load } = useChatVideos();
  const fetchData = () => {
    if (isAllLoaded || isLoading) return;
    load(chatId, data ? data.length : 0);
  };

  const imgPlaceholder = useImgPlaceholder();

  const setPreviewUrl = useChatDetailStore((s) => s.setPreviewUrl);
  const setPreviewOpen = useChatDetailStore((s) => s.setPreviewOpen);
  const setMessagesContent = useChatDetailStore((s) => s.setMessagesContent);
  const setPreviewIndex = useChatDetailStore((s) => s.setPreviewIndex);

  const onPreviewClick = (url: string) => {
    return () => {
      setPreviewUrl(url);
      setPreviewOpen(true);
    };
  };

  useEffect(() => {
    data && setMessagesContent(data);
  }, [data, setMessagesContent]);

  useEffect(() => {
    setPreviewIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cls.root}>
      {data && data.length > 0 && (
        <ul className={cls.list}>
          {data.map((v) => (
            <li key={v.url}>
              <button
                className={cls.preview}
                type="button"
                onClick={onPreviewClick(v.url as string)}
              >
                <ImgSkeleton
                  className={cls.preview_img}
                  src={v.thumbnail_url || imgPlaceholder}
                  alt=""
                />
                <span className={cls.play_icon}>
                  <PlayIcon />
                </span>
              </button>
            </li>
          ))}
        </ul>
      )}

      {!isLoading && data && data.length <= 0 && (
        <div className={attachmentsCls.empty}>
          <b>{t('chat.empty.videos.title')}</b>
          <p>{t('chat.empty.videos.text')}</p>
        </div>
      )}

      {isLoading && <Spinner centered color="var(--clr-blue)" size={32} />}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
};
