import cn from 'classnames';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDesktop } from '@/app/common-store';
import { useLang } from '@/hooks/useLang';
import { useUserActivity } from '@/modules/activity/api';
import { ChatCompanion } from '@/modules/chat/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { TimeAgo } from '@/ui/TimeAgo/TimeAgo';
import { formatDate } from '@/utils/date';

import cls from './Companion.module.scss';

type Props = {
  companion: ChatCompanion;
};

export const Companion: React.FC<Props> = ({ companion }) => {
  const { t } = useTranslation();
  const [lang] = useLang();
  const isDesktop = useDesktop();
  const [lastSeen, isOnline] = useUserActivity(companion?.user_id || null);

  const date = useMemo(
    () => (lastSeen ? new Date(lastSeen * 1000) : null),
    [lastSeen]
  );
  const createdDate = useMemo(
    () => new Date(companion.created_at * 1000),
    [companion.created_at]
  );
  const createdStr = formatDate(createdDate, 'MMMM y', lang);

  return (
    <div className={cls.root}>
      <div className={cls.avatar}>
        <Avatar
          size={isDesktop ? 120 : 80}
          src={companion.author_avatar_url || ''}
          user={{ id: companion.user_id, name: companion.name || '' }}
        />
      </div>

      <p className={cls.name}>{companion.name || companion.user_id}</p>

      <div className={cls.user_info}>
        <p className={cn({ [cls.status_online]: isOnline })}>
          {isOnline && t('chat.online')}
          {!isOnline && date && (
            <Trans
              t={t}
              i18nKey="chat.lastSeen"
              components={{ timeValue: <TimeAgo date={date} /> }}
            />
          )}
        </p>
        <span className={cls.text_separator}>{t('common.dot')}</span>
        <p>{t('since', { date: createdStr })}</p>
      </div>

      {companion.description && (
        <p className={cls.desc}>{companion.description}</p>
      )}
    </div>
  );
};
