import { axios } from '@/app/api';
import { UploadReq } from '@/modules/files/types';
import { getFormData } from '@/utils/network';

import { CreateAdvertReq, CreateAdvertRes, VinReadRes } from './types';

export function createAdvertisement(req: CreateAdvertReq) {
  return axios.post<CreateAdvertRes>(
    '/dealers/showrooms/advertisements/create',
    req
  );
}

export function vinRead(req: UploadReq, content_type?: string) {
  return axios.post<VinReadRes>('/vin/read', getFormData({ file: req.file }), {
    params: { content_type }
  });
}
