import cn from 'classnames';
import { RefObject, useMemo, useRef } from 'react';

import { useDetectVisible } from '@/hooks/useDetectVisible';
import { useElementScroll } from '@/hooks/useElementScroll';
import { Attachments } from '@/modules/chat/Detail/Profile/Attachments/Attachments';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { useChatUtilsStore } from '@/modules/chat/utils-store';

import { Buttons } from './Buttons/Buttons';
import { Companion } from './Companion/Companion';
import { Header } from './Header/Header';
import { ArrowIcon } from './icons';
import cls from './Profile.module.scss';

const scrollToTop = (el: RefObject<HTMLDivElement>) => {
  el.current && el.current.scrollTo({ top: 0, behavior: 'smooth' });
};

export function Profile() {
  const ref = useRef<HTMLDivElement>(null);
  const rootScroll = useElementScroll(ref);
  const profileRef = useRef<HTMLDivElement>(null);
  const observerOptions = useMemo(() => ({ threshold: [0.2] }), []);
  const isProfileBlockVisible = useDetectVisible(profileRef, observerOptions);

  const setChatOpen = useChatUtilsStore((s) => s.setChatOpen);

  const isProfileOpen = useChatDetailStore((s) => s.isProfileOpen);
  const setProfileOpen = useChatDetailStore((s) => s.setProfileOpen);
  const setSearchOpen = useChatDetailStore((s) => s.setSearchOpen);
  const detail = useChatDetailStore((s) => s.detail);
  const companion = detail?.companion;
  const userId = companion?.user_id;

  const onSearchClick = () => {
    setProfileOpen(false);
    setSearchOpen(true);
  };

  return (
    <div ref={ref} className={cn(cls.root, 'hide-scrollbar')} id="chat-profile">
      <Header onCloseClick={() => setProfileOpen(false)} scrollY={rootScroll} />

      <div className={cls.box} ref={profileRef}>
        {companion && <Companion companion={companion} />}
        {detail && (
          <Buttons
            chatId={detail.id}
            onSearchClick={onSearchClick}
            setChatOpen={setChatOpen}
            userId={userId}
            username={companion?.username}
          />
        )}
      </div>

      {isProfileOpen && detail && companion && (
        <Attachments
          chatId={detail.id}
          isHeader={!isProfileBlockVisible}
          companion={companion}
        />
      )}

      {!isProfileBlockVisible && (
        <button className={cls.btn_top} onClick={() => scrollToTop(ref)}>
          <ArrowIcon />
        </button>
      )}
    </div>
  );
}
