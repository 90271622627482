import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { Button } from '@/ui/Button/Button';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './Discounts.module.scss';

type Props = {
  advert: MyAdvert;
  setPriceEditOpen: (isOpen: boolean) => void;
};

export function Discounts({ advert, setPriceEditOpen }: Props) {
  const { t } = useTranslation();
  const [locale] = useLang();

  const { discount } = advert;
  const [isOpen, setOpen] = useState(false);
  const max = discount?.discount_amount
    ? String(discount?.discount_amount)
    : '';

  const handleEditClick = () => {
    setTimeout(() => {
      setPriceEditOpen(true);
    }, 1);

    setOpen(false);
  };

  if (!max) return <></>;

  return (
    <div className={cls.root} style={{ zIndex: isOpen ? 3 : 2 }}>
      <button
        className={cls.btn}
        type="button"
        onMouseEnter={() => setOpen(true)}
      >
        {t('discount.discounts')}
      </button>

      <div className={cls.dropdown_wrap} onMouseLeave={() => setOpen(false)}>
        <Dropdown
          name={`${advert.id}-discount-edit`}
          isOpen={isOpen}
          close={() => setOpen(false)}
          parentCls={cls.root}
          toLeft
        >
          <li>
            <div className={cls.dropdown_root}>
              <h2 className={cls.title}>{t('discount.title')}</h2>

              {discount && (
                <>
                  <div className={cls.discounts}>
                    {max && (
                      <h2>
                        {t('priceCurrency', {
                          price: numberWithSpaces(Number(max), locale),
                          currency: ADV_CUR
                        })}
                      </h2>
                    )}

                    {discount.discounts && discount.discounts.length > 0 && (
                      <div className={cls.available_discounts}>
                        <p>{t('discount.availableDiscounts')}</p>
                        <ul>
                          {discount.discounts.map((d) => (
                            <li key={d.id}>{d.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}

              <Button
                variant="secondary"
                color="black"
                onClick={handleEditClick}
              >
                {t('common.change')}
              </Button>
            </div>
          </li>
        </Dropdown>
      </div>
    </div>
  );
}
