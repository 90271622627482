export function SearchIcon(): JSX.Element {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4163 4.08301C18.0877 4.08301 21.8747 7.86993 21.8747 12.5413C21.8747 14.4561 21.2384 16.2222 20.1658 17.6399L25.033 22.508C25.4886 22.9637 25.4886 23.7024 25.033 24.158C24.5774 24.6136 23.8387 24.6136 23.383 24.158L18.5149 19.2908C17.0972 20.3634 15.3311 20.9997 13.4163 20.9997C8.74493 20.9997 4.95801 17.2127 4.95801 12.5413C4.95801 7.86993 8.74493 4.08301 13.4163 4.08301ZM13.4163 6.41634C10.0336 6.41634 7.29134 9.1586 7.29134 12.5413C7.29134 15.9241 10.0336 18.6663 13.4163 18.6663C16.7991 18.6663 19.5413 15.9241 19.5413 12.5413C19.5413 9.1586 16.7991 6.41634 13.4163 6.41634Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ProfileIcon(): JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.35 7.9498C15.35 6.37499 14.0748 5.0998 12.5 5.0998C10.9252 5.0998 9.64998 6.37499 9.64998 7.9498C9.64998 9.52462 10.9252 10.7998 12.5 10.7998C14.0748 10.7998 15.35 9.52462 15.35 7.9498ZM17.15 7.9498C17.15 10.5187 15.0689 12.5998 12.5 12.5998C9.93104 12.5998 7.84998 10.5187 7.84998 7.9498C7.84998 5.38087 9.93104 3.2998 12.5 3.2998C15.0689 3.2998 17.15 5.38087 17.15 7.9498ZM6.39998 18.4284C6.39998 19.1972 6.30921 19.0998 6.73527 19.0998H18.2647C18.6907 19.0998 18.6 19.1972 18.6 18.4284C18.6 16.5358 15.8218 15.3998 12.5 15.3998C9.17817 15.3998 6.39998 16.5358 6.39998 18.4284ZM4.59998 18.4284C4.59998 15.1016 8.27276 13.5998 12.5 13.5998C16.7272 13.5998 20.4 15.1016 20.4 18.4284C20.4 20.164 19.7144 20.8998 18.2647 20.8998H6.73527C5.28551 20.8998 4.59998 20.164 4.59998 18.4284Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function BlockIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26541 21.4911H16.4298C17.916 21.4911 18.6953 20.6941 18.6953 19.0897V12.1582C18.6953 10.5671 17.916 9.77003 16.4298 9.77003H7.26541C5.77745 9.77003 5 10.5671 5 12.1582V19.0897C5 20.6941 5.77745 21.4911 7.26541 21.4911ZM7.54334 19.7368C7.13661 19.7368 6.90428 19.4883 6.90428 19.0264V12.2249C6.90428 11.7612 7.13661 11.5244 7.54334 11.5244H16.1536C16.5685 11.5244 16.7892 11.7612 16.7892 12.2249V19.0264C16.7892 19.4883 16.5685 19.7368 16.1536 19.7368H7.54334ZM6.79017 10.5923H8.62281V7.29948C8.62281 5.00137 10.0928 3.75258 11.8436 3.75258C13.5909 3.75258 15.0823 5.00137 15.0823 7.29948V10.5923H16.9068V7.47329C16.9068 3.8622 14.517 2 11.8436 2C9.17832 2 6.79017 3.8622 6.79017 7.47329V10.5923Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function RemoveIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66207 17.7099C10.0072 17.7099 10.2297 17.4912 10.2215 17.1691L9.97054 8.58175C9.96087 8.26263 9.73546 8.05065 9.4052 8.05065C9.06299 8.05065 8.842 8.26934 8.85018 8.58994L9.09444 17.1735C9.10411 17.4994 9.33099 17.7099 9.66207 17.7099ZM12.0748 17.7099C12.4141 17.7099 12.6529 17.4941 12.6529 17.1751V8.58699C12.6529 8.26787 12.4141 8.05065 12.0748 8.05065C11.7341 8.05065 11.5019 8.26787 11.5019 8.58699V17.1751C11.5019 17.4941 11.7341 17.7099 12.0748 17.7099ZM14.4927 17.7099C14.8186 17.7099 15.044 17.4994 15.0536 17.1735L15.2979 8.58994C15.3061 8.26934 15.0851 8.05065 14.7429 8.05065C14.4126 8.05065 14.1872 8.26263 14.1776 8.58846L13.9334 17.1691C13.9251 17.4912 14.1476 17.7099 14.4927 17.7099ZM8.35365 5.76833H9.86537V4.06707C9.86537 3.64294 10.162 3.37083 10.6181 3.37083H13.5166C13.9726 3.37083 14.2693 3.64294 14.2693 4.06707V5.76833H15.7811V3.99105C15.7811 2.73849 14.9816 2 13.6243 2H10.5103C9.15309 2 8.35365 2.73849 8.35365 3.99105V5.76833ZM4.72489 6.65949H19.4299C19.8336 6.65949 20.1481 6.3389 20.1481 5.93526C20.1481 5.53455 19.8336 5.22068 19.4299 5.22068H4.72489C4.32796 5.22068 4 5.53455 4 5.93526C4 6.34562 4.32796 6.65949 4.72489 6.65949ZM8.31302 20.483H15.8418C17.0957 20.483 17.91 19.7154 17.9688 18.46L18.5319 6.50239H17.0126L16.4741 18.2161C16.4578 18.7075 16.1335 19.0339 15.6665 19.0339H8.47487C8.02126 19.0339 7.69706 18.6994 7.67396 18.2161L7.10862 6.50239H5.61624L6.186 18.4668C6.24629 19.7221 7.04572 20.483 8.31302 20.483Z"
        fill="#E60000"
      />
    </svg>
  );
}
