import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { BackLink } from '@/ui/BackLink/BackLink';

import cls from './Header.module.scss';
import { ArrowIcon } from './icons';

const SCROLL_BREAKPOINT = 1;

type Props = {
  onCloseClick: () => void;
  scrollY: number;
};

export const Header: React.FC<Props> = ({ onCloseClick, scrollY }) => {
  const { t } = useTranslation();

  const showStickyHeader = scrollY > SCROLL_BREAKPOINT;

  return (
    <header className={cn(cls.root, { [cls.root_sticky]: showStickyHeader })}>
      <div className={cls.row}>
        <button className={cls.close_btn} type="button" onClick={onCloseClick}>
          <ArrowIcon />
        </button>
        <h2 className={cls.title}>{t('common.back')}</h2>
      </div>

      <div className={cls.back}>
        <BackLink text={t('chat.one')} onClick={onCloseClick} />
      </div>
    </header>
  );
};
