export function CameraIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.87863 16.3535H16.12C17.5712 16.3535 18.3333 15.6025 18.3333 14.1636V7.08784C18.3333 5.64899 17.5712 4.90348 16.12 4.90348H14.3141C13.7851 4.90348 13.6135 4.80772 13.2894 4.46693L12.7561 3.88918C12.4055 3.52074 12.0519 3.33301 11.3659 3.33301H8.59527C7.90926 3.33301 7.55567 3.52074 7.20514 3.88918L6.67055 4.46693C6.34647 4.80219 6.17061 4.90348 5.64586 4.90348H3.87863C2.4232 4.90348 1.66663 5.64899 1.66663 7.08784V14.1636C1.66663 15.6025 2.4232 16.3535 3.87863 16.3535ZM9.99933 14.2478C7.97923 14.2478 6.35645 12.6305 6.35645 10.5969C6.35645 8.56889 7.97923 6.95283 9.99933 6.95283C12.0218 6.95283 13.6379 8.56889 13.6379 10.5969C13.6379 12.6305 12.0163 14.2478 9.99933 14.2478ZM9.99933 13.0432C11.3504 13.0432 12.4388 11.9578 12.4388 10.5969C12.4388 9.24035 11.3504 8.15189 9.99933 8.15189C8.65074 8.15189 7.5555 9.24035 7.5555 10.5969C7.5555 11.9578 8.65626 13.0432 9.99933 13.0432ZM14.0739 8.11912C14.0739 7.6436 14.4906 7.22688 14.9785 7.22688C15.4595 7.22688 15.8695 7.6436 15.8695 8.11912C15.8695 8.61366 15.4607 9.00894 14.9785 9.01568C14.4882 9.01689 14.0739 8.61919 14.0739 8.11912Z"
        fill="currentColor"
      />
    </svg>
  );
}
