import { useUserActivity } from '@/modules/activity/api';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatDetail } from '@/modules/chat/types';

import { Header } from './Header';

type Props = {
  detail: ChatDetail;
};

export function HeaderWrap({ detail }: Props) {
  const { ad, companion } = detail;
  const isDetailLoading = useChatDetailStore((s) => s.isDetailLoading);
  const isMsgsLoading = useChatDetailStore((s) => s.isMsgsLoading);
  const setProfileOpen = useChatDetailStore((s) => s.setProfileOpen);

  const [lastSeen, isOnline] = useUserActivity(companion?.user_id || null);

  return (
    <Header
      ad={ad}
      chatId={detail.id}
      companionId={companion?.user_id}
      name={companion?.name || ''}
      avatarUrl={companion?.author_avatar_url || ''}
      lastSeen={lastSeen || null}
      online={isOnline}
      loading={isDetailLoading || isMsgsLoading}
      openProfile={() => setProfileOpen(true)}
    />
  );
}
