import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks/useDebounce';
import { SearchNav } from '@/modules/chat/Detail/SearchNav/SearchNav';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { useSearchChatMessagesIds } from '@/modules/chat/hooks';
import { ChatMessageId } from '@/modules/chat/types';
import { InputSearch } from '@/ui/InputSearch/InputSearch';

import cls from './SearchBar.module.scss';

type Props = {
  chatId: number;
};

export function SearchBar({ chatId }: Props) {
  const { t } = useTranslation();

  const searchText = useChatDetailStore((s) => s.searchText);
  const setSearchText = useChatDetailStore((s) => s.setSearchText);
  const setSearchIds = useChatDetailStore((s) => s.setSearchIds);
  const setSearchIndex = useChatDetailStore((s) => s.setSearchIndex);
  const scrollToMsg = useChatDetailStore((s) => s.scrollToMsg);

  const debouncedSearch = useDebounce(searchText, 800);
  const [result, loading] = useSearchChatMessagesIds(chatId, debouncedSearch);

  const onResultChange = useCallback(
    (r: ChatMessageId[]) => {
      if (r && r.length > 0) {
        const ids = r.map((v) => v.id).reverse();
        const i = ids.length > 0 ? ids.length - 1 : 0;
        setSearchIndex(i);
        setSearchIds(ids);
        scrollToMsg(ids[i]);
      } else {
        setSearchIds([]);
        setSearchIndex(0);
      }
    },
    [setSearchIds, setSearchIndex, scrollToMsg]
  );

  useEffect(() => {
    onResultChange(result);
  }, [result, onResultChange]);

  return (
    <div className={cls.root}>
      <div className={cls.field}>
        <InputSearch
          value={searchText}
          onChange={setSearchText}
          placeholder={t('common.search')}
          disabled={loading}
          highlightFilled
          showClear
          compact
        />
      </div>
      <SearchNav />
    </div>
  );
}
