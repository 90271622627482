import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { acceptMap } from '@/modules/files/upload/helpers';
import { vinRead } from '@/modules/showroom/advert/create/api';
import { CameraIcon } from '@/modules/showroom/advert/create/VinSuggest/VinPhotoRecognition/icons';
import { showAlert } from '@/utils/network';

import cls from './VinPhotoRecognition.module.scss';

type Props = {
  onVinRecognized: (vin: string) => void;
};

export const VinPhotoRecognition: React.FC<Props> = ({ onVinRecognized }) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLInputElement>(null);
  const [isUploading, setUploading] = useState(false);

  const openUpload = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUploading) return;
    setUploading(true);

    const f = e.target.files ? e.target.files[0] : null;
    e.target.value = '';
    if (!f) {
      close();
      setUploading(false);
      return;
    }

    try {
      showAlert({
        promise: vinRead({ file: f }, f.type).then((res) => {
          if (res.data?.vin) onVinRecognized(res.data.vin);
        }),
        promiseTexts: {
          loadingText: t('vin.weSearchingVin'),
          successText: t('vin.vinFound'),
          errorText: t('vin.vinNotFound')
        }
      });
    } catch (error) {
      if (ref && ref.current) {
        ref.current.click();
      }
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <button
        className={cls.recognition}
        type="button"
        onClick={openUpload}
        disabled={isUploading}
      >
        {t('vin.photoIdentification')}
        <CameraIcon />
      </button>

      <input
        style={{ display: 'none' }}
        ref={ref}
        type="file"
        accept={acceptMap['image']}
        onChange={onFileChange}
      />
    </>
  );
};
