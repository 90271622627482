import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonObjWithPhoto, Nullable } from '@/app/types';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { useExpensesTypesList } from '@/modules/wallet/hooks';
import { ExpenseType } from '@/modules/wallet/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';

import cls from '../WalletPage.module.scss';

type Props = {
  setExpenseType: (v: Nullable<ExpenseType>) => void;
};

export const ExpensesTypeFilter: React.FC<Props> = ({ setExpenseType }) => {
  const { t } = useTranslation();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedExpensesTypeId, setSelectedExpensesTypeId] =
    useState<Nullable<number>>(null);

  const [expensesTypesList, expensesTypesLoading] = useExpensesTypesList();

  const expensesTypesOptions = useMemo<CommonObjWithPhoto[]>(
    () =>
      expensesTypesList.map((expenseType) => ({
        id: expenseType.id,
        name: expenseType.name,
        photo_url: expenseType.icon_svg,
        dark_mode_photo_url: expenseType.icon_svg
      })),
    [expensesTypesList]
  );

  const onAdCategoryChange = (v: CommonObjWithPhoto) => {
    setSelectedExpensesTypeId(v.id);
  };

  const expensesTypeName = useMemo(() => {
    if (!selectedExpensesTypeId) return '';

    return expensesTypesList.find(
      (expensesType) => expensesType.id === selectedExpensesTypeId
    )?.name;
  }, [expensesTypesList, selectedExpensesTypeId]);

  useEffect(() => {
    if (!selectedExpensesTypeId) {
      setExpenseType(null);
      return;
    }

    const newExpensesType = expensesTypesList.find(
      (expensesType) => expensesType.id === selectedExpensesTypeId
    );

    newExpensesType && setExpenseType(newExpensesType.title as ExpenseType);
  }, [expensesTypesList, selectedExpensesTypeId, setExpenseType]);

  return (
    <>
      <FilterBtn
        text={t('wallet.expensesType')}
        onClick={() => setDropdownOpen(true)}
        active={isDropdownOpen}
        value={expensesTypeName}
        solidBorder
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="expenses-types-list"
        isOpen={isDropdownOpen}
        close={() => setDropdownOpen(false)}
        parentCls={cls.li}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={selectedExpensesTypeId}
            list={expensesTypesOptions}
            onClick={onAdCategoryChange}
            loading={expensesTypesLoading}
            allButtonText={t('common.any')}
            onAllClick={() => setSelectedExpensesTypeId(null)}
            search=""
            showImage
            defaultAllButtonStyle
            showCheckIcon
          />
        </li>
      </Dropdown>
    </>
  );
};
