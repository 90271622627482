export function ChevronIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.01951 11.1913C4.79292 10.9696 4.66666 10.674 4.66666 10.3336C4.66666 9.64715 5.19909 9.11088 5.8837 9.11088C6.2279 9.11088 6.54534 9.23993 6.76791 9.47978L14.5203 17.414H13.4893L21.234 9.47978C21.4634 9.23804 21.7827 9.11088 22.1164 9.11088C22.799 9.11088 23.3333 9.64715 23.3333 10.3336C23.3333 10.6759 23.209 10.9714 22.9786 11.1913L14.9542 19.4035C14.6878 19.6853 14.3636 19.8297 14.0053 19.8335C13.6402 19.8335 13.3228 19.6872 13.0477 19.4035L5.01951 11.1913Z"
        fill="currentColor"
      />
    </svg>
  );
}
