import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { axios } from '@/app/api';
import { blockUserViaChat, unblockUserViaChat } from '@/modules/accounts/api';
import { CHAT_API_ROUTES } from '@/modules/chat/api';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatBlockStatusType } from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { APP_URL } from '@/utils/consts';
import { showAlert } from '@/utils/network';

import cls from './Buttons.module.scss';
import confirm from './confirm.json';
import { SearchIcon, RemoveIcon, ProfileIcon, BlockIcon } from './icons';

type Props = {
  chatId: number;
  userId?: number;
  username?: string;
  setChatOpen: (v: boolean) => void;
  onSearchClick: () => void;
};

export const Buttons: React.FC<Props> = ({
  chatId,
  userId,
  username,
  setChatOpen,
  onSearchClick
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const profileUrl = username
    ? `${APP_URL}/${username}`
    : `${APP_URL}/profile/${userId}`;
  const onProfileClick = () => {
    setChatOpen(false);
  };

  const updateChatResetKey = useChatUtilsStore((s) => s.updateChatResetKey);
  const updateBlockStatus = useChatDetailStore((s) => s.updateBlockStatus);
  const blockStatus = useChatDetailStore((s) => s.detail?.block_status);
  const detail = useChatDetailStore((s) => s.detail);

  // Block
  const [isBlockConfirmOpen, setBlockConfirmOpen] = useState(false);
  const onBlockSubmit = async (blocking: boolean) => {
    setBlockConfirmOpen(false);

    if (!detail) return;
    const advertisement_id = detail.ad?.id;
    const companionId = detail.companion?.user_id;
    if (!advertisement_id || !companionId) return;

    const promise = !blocking
      ? unblockUserViaChat(companionId, { advertisement_id })
      : blockUserViaChat(companionId, { advertisement_id });

    promise
      .then((r) => {
        if (r.data) {
          updateBlockStatus(r.data);
          // getBlockList();
        }
      })
      .catch((error) => showAlert({ error }));
  };

  // Delete
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const onDeleteSubmit = async () => {
    setDeleteConfirmOpen(false);

    try {
      await axios.delete(CHAT_API_ROUTES.deleteChat(chatId));
      showAlert({ type: 'success', text: t('chat.actions.deleted') });
      updateChatResetKey();
      navigate('/chat');
    } catch (error) {
      showAlert({ error });
    }
  };

  return (
    <>
      <div className={cls.root}>
        <ul className={cls.list}>
          <li>
            <a
              className={cls.btn}
              onClick={onProfileClick}
              href={profileUrl}
              target="_blank"
              rel="noreferrer"
            >
              <ProfileIcon />
              <span>{t('profile.title')}</span>
            </a>
          </li>

          <li>
            <button className={cls.btn} type="button" onClick={onSearchClick}>
              <SearchIcon />
              <span>{t('common.search')}</span>
            </button>
          </li>

          {blockStatus && blockStatus.title ? (
            <>
              {blockStatus.title === ChatBlockStatusType.do_unblock && (
                <li>
                  <button
                    className={cls.btn}
                    onClick={() => {
                      onBlockSubmit(false);
                    }}
                  >
                    <BlockIcon />
                    <span>{blockStatus.name}</span>
                  </button>
                </li>
              )}
            </>
          ) : (
            <li>
              <button
                className={cls.btn}
                onClick={() => {
                  setBlockConfirmOpen(true);
                }}
              >
                <BlockIcon />
                <span>{t('chat.actions.block')}</span>
              </button>
            </li>
          )}

          <li className={cls.li_more}>
            <button
              className={cn(cls.btn, cls.btn_remove)}
              type="button"
              onClick={() => setDeleteConfirmOpen(true)}
            >
              <RemoveIcon />
              <span>{t('chat.actions.delete')}</span>
            </button>
          </li>
        </ul>
      </div>

      <ConfirmModal
        name="chat-block-confirm"
        isOpen={isBlockConfirmOpen}
        close={() => setBlockConfirmOpen(false)}
        onButtonClick={(confirmed) => {
          if (confirmed) {
            onBlockSubmit(true);
          } else {
            setBlockConfirmOpen(false);
          }
        }}
        title={t('chat.actions.blockConfirm')}
        confirmText={t('chat.actions.block')}
        cancelText={t('common.cancel')}
      />

      <ConfirmModal
        name="chat-delete-confirm"
        isOpen={isDeleteConfirmOpen}
        close={() => setDeleteConfirmOpen(false)}
        onButtonClick={(confirmed) => {
          if (confirmed) {
            onDeleteSubmit();
          } else {
            setDeleteConfirmOpen(false);
          }
        }}
        lottieData={confirm}
        title={t('chat.actions.deleteTitle')}
        text={t('chat.actions.deleteText')}
        confirmText={t('chat.actions.delete')}
        cancelText={t('common.cancel')}
      />
    </>
  );
};
