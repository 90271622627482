import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Nullable } from '@/app/types';
import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import {
  ExpenseCategory,
  ExpensePromotion,
  StatisticAdvert,
  StatisticsDay
} from '@/modules/wallet/types';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { APP_URL, CURRENCY_DISPLAY } from '@/utils/consts';
import { formatUnixTime } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';

import cls from './ExpensesDefault.module.scss';

type Props = {
  expense: StatisticsDay;
  advert: StatisticAdvert;
  categories: Record<string, ExpenseCategory>;
  promotion: Record<string, ExpensePromotion>;
};

export function ExpensesDefaultRow({
  expense,
  advert,
  categories,
  promotion
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();
  const navigate = useNavigate();

  const imgPlaceholder = useImgPlaceholder();
  const name = useAdvertName(advert);

  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';

  const categoryIdStr = useMemo(
    () => expense.category_id.toString(),
    [expense.category_id]
  );
  const activePromoIdStr = useMemo<Nullable<string>>(
    () =>
      expense.activated_promotion_package_id
        ? expense.activated_promotion_package_id.toString()
        : null,
    [expense.activated_promotion_package_id]
  );

  const expenseName = useMemo(() => {
    if (!activePromoIdStr) return categories[categoryIdStr]?.name;

    return promotion[activePromoIdStr]?.name;
  }, [activePromoIdStr, categories, categoryIdStr, promotion]);

  const appliedAt = useMemo(() => {
    const formattedTime = formatUnixTime(expense.created_at);

    return t('wallet.appliedAt', { time: formattedTime.time });
  }, [expense.created_at, t]);

  const imgSrc = useMemo(() => {
    const advert = expense.advertisement;
    if (!advert) return imgPlaceholder;

    if (advert.vrp_image_url) return advert.vrp_image_url;
    if (advert.photos && advert.photos[0]) {
      return advert.photos[0].thumbnail_url || advert.photos[0].photo_url;
    }

    return imgPlaceholder;
  }, [expense, imgPlaceholder]);

  const iconSrc = useMemo(() => {
    if (!activePromoIdStr) return categories[categoryIdStr]?.icon_svg || '';

    return promotion[activePromoIdStr]?.logo_url || '';
  }, [categories, categoryIdStr, activePromoIdStr, promotion]);

  // Show in ads
  const setVin = useSearchStore((s) => s.setVin);
  const setVrpPlateNumbers = useSearchStore((s) => s.setVrpPlateNumbers);
  const setVrpTypeId = useSearchStore((s) => s.setVrpTypeId);

  const handleShowInAdsClick = async () => {
    if (advert.category?.id === CARS_CATEGORY_ID) {
      if (advert.vin) setVin(advert.vin);
      navigate('/adverts/cars');
    }

    if (advert.category?.id === VRP_CATEGORY_ID) {
      !!advert.vrp_plate_number &&
        setVrpPlateNumbers(Number(advert.vrp_plate_number));
      !!advert.vrp_type?.id && setVrpTypeId(advert.vrp_type.id);
      navigate('/adverts/vrp');
    }
  };

  return (
    <div className={cls.expense_row}>
      <div className={cls.expense_header}>
        <div className={cls.expense_type}>
          <div className={cls.expense_icon}>
            {iconSrc && <img src={iconSrc} />}
          </div>
          <p>{expenseName}</p>
          <small>{appliedAt}</small>
        </div>

        <p className={cls.expense_cost}>
          {t('common.hyphenSeparatedShort', {
            first: '',
            second: t('priceCurrency', {
              price: numberWithSpaces(expense.amount, lang),
              currency: CURRENCY_DISPLAY.aed
            })
          })}
        </p>
      </div>

      <div className={cls.expense_advert}>
        <div className={cls.advert_img}>
          <ImgSkeleton
            className={cls.img_inner}
            src={imgSrc}
            alt=""
            imgProxyWidth={72}
            imgProxyHeight={54}
          />
        </div>

        <div className={cls.advert_info}>
          <p className={cls.advert_name}>
            {name}
            {advert.status?.title !== AdvertStatusType.published && (
              <span>{advert.status?.name}</span>
            )}
          </p>
          <p className={cls.advert_price}>
            {!!advert.price &&
              t('priceCurrency', {
                price: numberWithSpaces(advert.price, lang),
                currency: CURRENCY_DISPLAY.aed
              })}
          </p>
        </div>

        <div className={cls.actions}>
          <ButtonLink
            to={advertUrl}
            size="compact"
            variant="outline"
            color="black"
            externalLink
          >
            {t('wallet.showInClient')}
          </ButtonLink>

          <Button
            onClick={handleShowInAdsClick}
            size="compact"
            variant="outline"
            color="black"
          >
            {t('wallet.showInAds')}
          </Button>
        </div>
      </div>
    </div>
  );
}
