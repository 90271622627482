import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { Nullable, SortType } from '@/app/types';
import { useLeadsPg } from '@/modules/leads/hooks';
import { LeadRow } from '@/modules/leads/LeadsPage/Leads/LeadRow/LeadRow';
import { LeadRowSkeleton } from '@/modules/leads/LeadsPage/Leads/LeadRow/LeadRowSkeleton';
import { GetLeadsReq } from '@/modules/leads/types';

import cls from './Leads.module.scss';

const skeletonsArr = new Array(4).fill(null);

type Props = {
  sort: Nullable<SortType>;
  search: string;
  req: GetLeadsReq;
  hasFilters: boolean;
};

export function Leads({
  sort,
  search,
  req,
  hasFilters: hasFiltersProp
}: Props) {
  const { t } = useTranslation();

  const hasFilters = !!req.lead_type && hasFiltersProp;
  const queryReq = useMemo(
    () => ({ order_by: sort, search: search || null }),
    [search, sort]
  );
  const { isAllLoaded, isLoading, data, load } = useLeadsPg(queryReq, req);

  const fetchData = () => {
    if (isAllLoaded || isLoading) return;
    load(data ? data.length : 0);
  };

  return (
    <div className={cls.root}>
      {data && data.length > 0 && (
        <ul className={cls.list}>
          {data.map((l) => (
            <li key={l.id}>
              <LeadRow lead={l} />
            </li>
          ))}
        </ul>
      )}

      {!isLoading && data && data.length <= 0 && (
        <p className={cls.empty}>
          {t(hasFilters ? 'leads.emptyWithFilters' : 'leads.empty')}
        </p>
      )}

      {isLoading && (
        <>
          {skeletonsArr.map((_s, i) => (
            <LeadRowSkeleton key={i} />
          ))}
        </>
      )}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
}
