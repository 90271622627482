import { useTranslation } from 'react-i18next';

import creditCls from '@/modules/credit/CreditRequests/CreditRequest/CreditRequest.module.scss';
import { Skeleton } from '@/ui/Skeleton';

export function LeadRowSkeleton() {
  const { t } = useTranslation();

  return (
    <div className={creditCls.root}>
      <div className={creditCls.col}>
        <div className={creditCls.img}>
          <Skeleton className={creditCls.img_inner} />
        </div>
      </div>

      <div className={creditCls.col}>
        <div className={creditCls.advert_wrap}>
          <p className={creditCls.advert}>
            <Skeleton width={180} height={24} />
          </p>
        </div>
        <Skeleton width={320} height={20} />

        <div style={{ marginTop: '12px' }}>
          <Skeleton width={96} height={30} />
        </div>

        <div className={creditCls.date}>
          <Skeleton width={160} height={20} />
        </div>
      </div>

      <div className={creditCls.col}>
        <p className={creditCls.col_label}>{t('credits.req.aboutLead')}</p>

        <div className={creditCls.contact}>
          <Skeleton width={124} height={20} />
        </div>
        <div className={creditCls.contact}>
          <Skeleton width={124} height={20} />
        </div>
        <div className={creditCls.contact}>
          <Skeleton width={124} height={20} />
        </div>
      </div>
    </div>
  );
}
