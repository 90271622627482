import cn from 'classnames';
import Tooltip from 'rc-tooltip';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EngagementByDaySmall, Nullable } from '@/app/types';
import { EngagementGraph } from '@/modules/overview/CountCard/EngagementGraph/EngagementGraph';
import {
  InfoIcon,
  ChevronIcon,
  ArrowIcon
} from '@/modules/overview/CountCard/icons';
import { Card } from '@/ui/Card/Card';
import { Spinner } from '@/ui/Spinner/Spinner';
import { rcTooltipProps } from '@/utils/consts';

import cls from './GraphView.module.scss';

type Props = {
  data?: EngagementByDaySmall[];
  title?: Nullable<string>;
  amount?: string;
  conversion?: string;
  trend?: string;
  negativeTrend?: boolean;
  tooltipOverlay?: Nullable<string>;
  dataLoading?: boolean;
  chartHeight?: number;
  datesRange?: [Date, Date];
  axisColor?: string;
  cardStyle?: React.CSSProperties;
  linkTo?: string;
};

export function GraphView({
  data,
  title,
  amount,
  conversion,
  trend,
  negativeTrend,
  tooltipOverlay,
  dataLoading,
  chartHeight,
  datesRange,
  axisColor,
  cardStyle,
  linkTo
}: Props) {
  const { t } = useTranslation();

  return (
    <Card className={cls.card} style={cardStyle} flat>
      <div className={cls.header}>
        {!linkTo && <p className={cls.title}>{title}</p>}
        {!!linkTo && (
          <Link className={cn(cls.title, cls.link)} to={linkTo}>
            {title}
            <ArrowIcon />
          </Link>
        )}

        <Tooltip
          placement="top"
          overlay={tooltipOverlay}
          overlayClassName={cls.tooltip_overlay}
          {...rcTooltipProps}
        >
          <div className={cls.info}>
            <InfoIcon />
          </div>
        </Tooltip>
      </div>

      <div className={cls.amount_wrap}>
        {amount && <b className={cls.amount}>{amount}</b>}
        {trend && (
          <div
            className={cn(cls.trend, { [cls.trend_negative]: negativeTrend })}
          >
            <ChevronIcon />
            {trend}
          </div>
        )}
      </div>
      {conversion && (
        <p className={cls.conversion}>
          {conversion} <span>{t('ofConversion')}</span>
        </p>
      )}

      <div className={cls.graph}>
        {!dataLoading && data && (
          <EngagementGraph
            data={data}
            datesRange={datesRange}
            chartHeight={chartHeight}
            axisColor={axisColor}
          />
        )}

        {dataLoading && (
          <div
            className={cls.spinner}
            style={{ minHeight: chartHeight || 289 }}
          >
            <Spinner color="var(--clr-blue)" />
          </div>
        )}
      </div>
    </Card>
  );
}
